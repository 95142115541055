import { Box, FormLabel, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../../entities/Experience';
import { LanguageJSON } from '../../../../entities/Language';
import { ProductJSON } from '../../../../entities/Product';
import { UserDetails } from '../../../../entities/User';
import { isBoxShipping, isVirtual } from '../../../../utils/experiences';
import { getMaxThreshold, isParticipantsRangeValid } from '../../../../utils/products';
import { CitySearchParams } from '../../../AddressSearch';
import PackageRadioGroup from '../../../PackageRadioGroup';
import RadioCard from '../../../RadioCard';
import { TimeSlot } from '../../../TimeSlotSelector';
import { Step } from '../Step';
import { BoxShippingFields } from './BoxShippingFields';
import { ExperienceFields } from './ExperienceFields';

export interface Props {
  type: ExperienceJSON['type'];
  products: ProductJSON[];
  productId: number;
  participants: number;
  targetCurrency: UserDetails['currency'];
  date: Date;
  languages: LanguageJSON[];
  languageId: number;
  isSubmitDisabled: boolean;
  bookingDeadline?: number;
  minDuration?: number;
  isGeneric?: boolean;
  onParticipantsChange(participants: number): void;
  onDateChange(date: Date): void;
  onTimeChange(slot: TimeSlot): void;
  onLanguageChange(languageId: number): void;
  onCityChange?(data: CitySearchParams): void;
  onProductChange?(productId: number): void;
  onInfoNext?(): Promise<void>;
}

export const DEFAULT_PARTICIPANTS = 5;

export function InfoStep(props: Props) {
  const { t } = useTranslation('experience');

  const product = props.products.find(({ id }) => id === props.productId);

  const areParticipantsValid = product ? isParticipantsRangeValid(product, props.participants) : true;
  const maxParticipants = product ? getMaxThreshold(product) : Infinity;

  return (
    <Step
      product={product}
      participants={props.participants}
      submitLabel={t('form.next')}
      targetCurrency={props.targetCurrency}
      isSubmitDisabled={props.isSubmitDisabled}
      onSubmit={props.onInfoNext}
    >
      {
        isBoxShipping({ type: props.type }) ? (
          <BoxShippingFields
            participants={props.participants}
            maxParticipants={maxParticipants}
            areParticipantsValid={areParticipantsValid}
            date={props.date}
            bookingDeadline={props.bookingDeadline}
            onParticipantsChange={props.onParticipantsChange}
            onDateChange={props.onDateChange}
          />
        ) : (
          <ExperienceFields
            participants={props.participants}
            maxParticipants={maxParticipants}
            areParticipantsValid={areParticipantsValid}
            date={props.date}
            minDuration={props.minDuration}
            languages={props.languages}
            languageId={props.languageId}
            isGeneric={props.isGeneric}
            isVirtual={isVirtual({ type: props.type })}
            bookingDeadline={props.bookingDeadline}
            onParticipantsChange={props.onParticipantsChange}
            onDateChange={props.onDateChange}
            onTimeChange={props.onTimeChange}
            onLanguageChange={props.onLanguageChange}
            onCityChange={props.onCityChange}
          />
        )
      }

      {
        props.products.length > 0 ? (
          <PackageRadioGroup
            currentProductId={props.productId}
            products={props.products}
            quantity={props.participants}
            targetCurrency={props.targetCurrency}
            onChange={props.onProductChange}
          />
        ) : (
          <Box w="100%">
            <FormLabel>{t('form.steps.information.package.label')}</FormLabel>

            <RadioCard
              label={t('form.steps.information.package.customRequest.label')}
              labelInfo={<Text textAlign="right" minW="130px">{t('form.steps.information.package.customRequest.labelInfo')}</Text>}
              onSelect={() => null}
              isSelected
            />
          </Box>
        )
      }
    </Step>
  );
}