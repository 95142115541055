import { Box, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Modal, ModalBody, ModalContent, ModalOverlay, Text, useToast, VStack } from '@chakra-ui/react';
import { BudgetSelect } from '@jurnee/common/src/components/BudgetSelect';
import { Loader } from '@jurnee/common/src/components/Loader';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import router from 'src/router';
import { useAppDispatch } from 'src/store';
import { createBooking } from 'src/store/bookings/bookings.thunks';
import { getBudgetsByIds } from 'src/store/budgets/budgets.selectors';
import { getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { trackEvent } from '../store/analytics/analytics.thunks';

interface Props {
  children: React.ReactElement;
}

export function CreateBookingModal(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['bookings', 'common']);

  const [budgetIds, setBudgetIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [areBudgetsLoading, setAreBudgetsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [name, setName] = useState(null);
  const [budgetId, setBudgetId] = useState(null);

  const isNameInvalid = !name || isEmpty(name);

  const budgets = useSelector(getBudgetsByIds(budgetIds));

  async function fetchUserBudgets() {
    const { list } = await dispatch(getUserBudgets()).unwrap();
    setBudgetIds(list.map(budget => budget.id));
  }

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
    setIsSubmitted(false);
  }

  async function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setAreBudgetsLoading(true);
    await fetchUserBudgets();
    setAreBudgetsLoading(false);

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_create_booking_modal'
    }));
  }

  async function onSave() {
    setIsSubmitted(true);

    if (isNameInvalid) {
      return;
    }

    setIsSaving(true);

    try {
      const booking = await dispatch(
        createBooking({
          body: {
            name,
            budgetId: budgetId || null,
            origin: 'DASHBOARD_EVENT_FORM'
          }
        })
      ).unwrap();

      toast(getSuccessToast(t('modal.newEvent.toasts.success')));
      onClose();
      router.navigate(`/bookings/${booking.data.id}`);
    } catch(error) {
      setIsSaving(false);
      setIsSubmitted(false);
      toast(getErrorToast(t('modal.newEvent.toasts.error'), error.message));
    }
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0} border={0} bg="white">
            <HStack spacing={0}>
              <VStack textAlign="center" width={500} spacing={5} p={10}>
                {
                  areBudgetsLoading ? (
                    <Loader h={300} />
                  ) : (
                    <>
                      <VStack spacing={4}>
                        <Heading size="md">{t('modal.newEvent.title')}</Heading>
                        <Text fontSize={14} color="gray.400" whiteSpace="pre-line">{t('modal.newEvent.description')}</Text>
                      </VStack>

                      <FormControl id="name" maxW={360} isRequired={true} isInvalid={isSubmitted && isNameInvalid}>
                        <FormLabel>{t('common:fields.eventName.label')}</FormLabel>
                        <Input
                          size="sm"
                          name="name"
                          defaultValue={name}
                          onChange={({ target }) => setName(target.value.trim())}
                          placeholder={t('common:fields.eventName.placeholder')}
                        />
                        <FormErrorMessage>{t('common:fields.eventName.error')}</FormErrorMessage>
                      </FormControl>

                      {
                        budgetIds.length > 0 && (
                          <BudgetSelect
                            size="sm"
                            maxW={360}
                            label={t('common:fields.budget.label')}
                            placeholder={t('common:fields.budget.placeholder')}
                            budgets={budgets}
                            onChange={setBudgetId}
                          />
                        )
                      }

                      <PrimaryButton size="sm" onClick={onSave} isLoading={isSaving}>
                        { t('modal.newEvent.submit') }
                      </PrimaryButton>
                    </>
                  )
                }
              </VStack>

              <Box
                width={300}
                bg={`url('/assets/images/new_event_modal.jpeg')`}
                bgSize="cover"
                bgPosition="center"
                alignSelf="stretch"
              />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}