import ProductEntity, { ProductJSON } from '../entities/Product';
import { sortBy } from './arrays';

export function computePrice({ fixedPrice, minSpent, pricings }: ProductEntity | ProductJSON, quantity: number) {
  const pricing = sortBy(pricings, 'threshold').find(({ threshold }) => quantity <= threshold);

  if (!pricing) {
    throw new Error('Missing pricing');
  }

  const price = fixedPrice + pricing.groupPrice + (quantity * pricing.unitPrice);

  if (price < minSpent) {
    return minSpent;
  }

  return price;
}

export function getMaxThreshold({ pricings }: Pick<ProductJSON, 'pricings'>) {
  return Math.max(...pricings.map(({ threshold }) => threshold));
}

export function getHighestThresholdPricing({ pricings }: Pick<ProductJSON, 'pricings'>) {
  return pricings.sort((a,b) => b.threshold - a.threshold)[0];
}

export function isParticipantsRangeValid(product: Pick<ProductJSON, 'pricings'>, value: number): boolean {
  const maxThreshold = getMaxThreshold(product);

  return value > 0 && value <= maxThreshold;
}

export function getMinPricePerUnit({ minSpent, fixedPrice, pricings }: Pick<ProductEntity, 'minSpent' | 'fixedPrice' | 'pricings'>) {
  return Math.min(
    ...pricings.map(({ threshold, groupPrice, unitPrice }) => {
      const price = fixedPrice + groupPrice + (threshold * unitPrice);

      return (price < minSpent ? minSpent : price) / threshold;
    })
  );
}

export function getCheapestPackageUnitPrice(products: ProductJSON[], quantity: number) {
  return Math.min(
    ...products
      .filter(product => isParticipantsRangeValid(product, quantity))
      .map(product => computePrice(product, quantity) / quantity)
  );
}

export function isCustomRequestProduct({ pricings }: ProductJSON) {
  if (pricings.length === 0) {
    return false;
  }

  return pricings.every(({ groupPrice, unitPrice }) => groupPrice === 0 && unitPrice === 0);
}

export function getProductPrice(product: ProductJSON, quantity: number) {
  if (!isParticipantsRangeValid(product, quantity)) {
    return null;
  }

  return computePrice(product, quantity);
}

export function getPackagePrice(product: ProductJSON, quantity: number) {
  const maxThreshold = getMaxThreshold(product);

  if (quantity > maxThreshold) {
    return getProductPrice(product, maxThreshold);
  }

  if (quantity < 1) {
    return getProductPrice(product, 1);
  }

  return getProductPrice(product, quantity);
}

export function sortByPackagePrice(products: ProductJSON[], quantity: number) {
  return [...products].sort((a, b) => {
    const priceA = getPackagePrice(a, quantity) ?? 0;
    const priceB = getPackagePrice(b, quantity) ?? 0;

    if (priceA === 0) {
      return 1;
    }

    if (priceB === 0) {
      return -1;
    }

    return priceA - priceB;
  });
}

export function getProductWithMinUnitPrice(products: ProductJSON[]): ProductJSON {
  return products.reduce((out, product) => product.minPricePerUnit < out.minPricePerUnit ? product : out, products[0]);
}