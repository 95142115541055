import { Box, Heading } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import * as React from 'react';
import { connect } from 'react-redux';
import EmptyState from 'src/components/EmptyState';
import { Experience as ExperienceCard } from 'src/components/Experience/ExperienceCard/Experience';
import { ExperiencesGrid } from 'src/components/Experience/ExperiencesGrid';
import { RootState } from 'src/store/state';
import { getUserFavoriteExperiences } from '../../store/userFavoriteExperiences/userFavoriteExperiences.thunks';
import { RouteProps } from '../Route';

interface StateProps {
  userFavoriteExperiences: RootState['userFavoriteExperiences'];
}

interface DispatchProps {
  getUserFavoriteExperiences(): void;
}

class Favorites extends React.Component<StateProps & DispatchProps> {

  constructor(props: StateProps & DispatchProps) {
    super(props);

    if (props.userFavoriteExperiences.status === FETCH_STATUS.INITIAL) {
      props.getUserFavoriteExperiences();
    }
  }

  renderList() {
    if (
      this.props.userFavoriteExperiences.status === FETCH_STATUS.INITIAL
    ) {
      return <Loader />;
    }

    if (this.props.userFavoriteExperiences.list.length === 0) {
      return <EmptyState
        imagePath="/assets/illustrations/heart.svg"
        heading="You currently have no favorites"
        description="Your favorites helps you keep track of the experiences you enjoy, but it's also a way to learn about trends in your team."
      />;
    }

    return (
      <ExperiencesGrid>
        {this.props.userFavoriteExperiences.list.map((experience) => (
          <ExperienceCard key={experience.id} experience={experience} />
        ))}
      </ExperiencesGrid>
    );
  }

  render() {
    return (
      <main>
        <Heading bg="white" p={8} borderBottom="1px solid" borderColor="blue.50">My favorites experiences</Heading>
        <Box p={8}>
          {this.renderList()}
        </Box>
      </main>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    userFavoriteExperiences: state.userFavoriteExperiences
  };
}

const mapDispatchToProps: DispatchProps = {
  getUserFavoriteExperiences
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Favorites);