import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Properties } from 'src/pages/Experiences/ExperiencesMap';

const hStackStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: 'var(--chakra-space-3)',
  width: '100%'
};

const vStackStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: 'var(--chakra-space-3)',
  width: '100%'
};

const imgStyle: CSSProperties = {
  width: '70px',
  minWidth: '70px',
  aspectRatio: '1',
  padding: 4,
  backgroundColor: 'gray.400',
  borderRadius: 4
};

const ellipsisStyle: CSSProperties = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%'
};

const titleStyle: CSSProperties = {
  fontWeight: 700,
  lineHeight: '16px',
  ...ellipsisStyle
};

const pStyle: CSSProperties = {
  lineHeight: '16px',
  fontWeight: 500,
  whiteSpace: 'nowrap',
};

export function PopupContent(props: Properties) {
  const { t } = useTranslation('experience');

  return (
    <div style={hStackStyle}>
      <div style={{ ...imgStyle, background: `linear-gradient(rgba(30, 0, 50, 0.1), rgba(30, 0, 50, 0.1)), url('${props.src}') no-repeat center / cover`}} />

      <div style={{ ...vStackStyle, width: '220px' }}>
        <div style={{ ...vStackStyle, gap: 'var(--chakra-space-2)' }}>
          <p style={titleStyle}>{props.title}</p>

          <div style={{ ...hStackStyle, gap: 'var(--chakra-space-1)' }}>
            <svg viewBox="0 0 24 24" fillRule="evenodd" focusable={false} style={{ width: '16px', height: '16px', flexShrink: 0 }}>
              <path fill="currentColor" d="M11.3379 2.83173C11.7674 1.72276 13.2326 1.72276 13.6621 2.83173L15.6584 7.98734L20.8401 8.4332C21.9562 8.52896 22.4087 10.0241 21.5582 10.8066L17.6107 14.4383L18.8159 19.8678C19.0758 21.0375 17.8916 21.9612 16.9366 21.3351L12.5 18.4252L8.06341 21.3351C7.10839 21.9612 5.92422 21.0365 6.18407 19.8678L7.38934 14.4383L3.44176 10.8066C2.59126 10.0241 3.04384 8.52896 4.15994 8.4332L9.34155 7.98734L11.3379 2.83276V2.83173Z" />
            </svg>

            {
              props.ratingCount > 0 ? (
                <p style={pStyle}>
                  { props.ratingAverage.toFixed(1) } ({ props.ratingCount })
                </p>
              ) : (
                <p style={pStyle}>-</p>
              )
            }

            { props.address && <p style={{ ...pStyle, ...ellipsisStyle }}>· {props.address}</p> }
          </div>
        </div>

        {
          !props.price ? (
            <p style={{ color: 'var(--chakra-colors-gray-400)', lineHeight: '16px', ...ellipsisStyle }}>
              { t('price.priceUponRequest') }
            </p>
          ) : (
            <p style={{ fontWeight: 700, lineHeight: '16px', ...ellipsisStyle }}>
              { props.price } { t('price.perPers') }
            </p>
          )
        }
      </div>
    </div>
  );
};