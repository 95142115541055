import { Image as ChakraImage, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ImageJSON } from '@jurnee/common/src/entities/Image';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { preloadImages } from '../utils/images';

interface Props {
  images: ImageJSON[];
  children: React.ReactElement;
}

export function PhotosDrawer(props: Props) {
  const { t } = useTranslation('experience');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);

  async function preload() {
    await preloadImages(props.images);
    setIsLoading(false);
  }

  useEffect(() => {
    preload();
  }, [props.images]);

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { t('drawers.photos.title') }
          </DrawerHeader>

          {
            isLoading ? (
              <DrawerBody w="100%" p={5}>
                <Loader />
              </DrawerBody>
            ) : (
              <DrawerBody w="100%" p={5} sx={{ columnCount: [2], columnGap: 2 }}>
                {
                  props.images.map(({ id, path }) => {
                    const src = path.startsWith('https') ? path : getCdnImageUrl(path);

                    return <ChakraImage
                      key={id}
                      w="100%"
                      mb={1}
                      display="inline-block"
                      src={src}
                    />;
                  })
                }
              </DrawerBody>
            )
          }
        </DrawerContent>
      </Drawer>
    </>
  );
}