import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getCompanySelector } from 'src/store/company/company.selector';
import { getExperienceCategories } from 'src/utils/experiences';

interface Props {
  query: string;
  booking?: BookingJSON;
}

export function ExperiencesTabs(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('experiences', { keyPrefix: 'categories' });

  const { excludedCatalogs } = useSelector(getCompanySelector);

  const categories = useMemo(
    () => getExperienceCategories({ excludedCatalogs, hasPath: true }),
    [excludedCatalogs]
  );

  function getIndex(): number {
    const index = categories.findIndex(({ path }) => path === location.pathname);

    return index > -1 ? index : 0;
  }

  function onChange(index: number) {
    const { path } = categories[index];

    const params = new URLSearchParams();

    if (!isEmpty(props.query)) {
      params.set('query', props.query);
    }

    return navigate(`${path}?${params.toString()}`, { state: { booking: props.booking }});
  }

  return (
    <Tabs w="100%" defaultIndex={getIndex()} onChange={onChange}>
      <TabList>
        { categories.map(({ type }) => <Tab key={type}>{t(type)}</Tab>) }
      </TabList>
    </Tabs>
  );
}