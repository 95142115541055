import { CircleLayerSpecification, SymbolLayerSpecification } from 'mapbox-gl';

export const clustersLayer: CircleLayerSpecification = {
  id: 'clusters',
  type: 'circle',
  source: 'experiences',
  filter: ['has', 'point_count'],
  paint: {
    'circle-radius': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      14,
      12
    ],
    'circle-color': '#000000',
    'circle-stroke-color': 'rgba(0, 0, 0, 0.25)',
    'circle-stroke-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      2,
      4
    ],
  }
};

export const clusterCountLayer: SymbolLayerSpecification = {
  id: 'clusters-count',
  type: 'symbol',
  source: 'experiences',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Inter Bold', 'Arial Unicode MS Bold'],
    'text-size': 12
  },
  paint: {
    'text-color': '#ffffff'
  }
};

export const pointsLayer: CircleLayerSpecification = {
  id: 'points',
  type: 'circle',
  source: 'experiences',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#000000',
    'circle-radius': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      6,
      4
    ],
    'circle-stroke-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      2,
      4
    ],
    'circle-stroke-color': 'rgba(0, 0, 0, 0.25)'
  }
};