import { Heading, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../../entities/Experience';
import { sanitize } from '../../../../utils/strings';
import { Icon } from '../../../Icon';
import { SkeletonText } from '../../../Skeleton/SkeletonText';
import { Highlights } from './Highlights';
import { Specifications } from './Specifications';

interface Props {
  description: { content: string, isGenerated?: boolean };
  maxDuration: number;
  maxParticipants: number;
  type: ExperienceJSON['type'];
  highlights: string[];
  isLoading?: boolean;
}

export function Content(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.description' });

  const hasHightlights = props.highlights.length > 0;

  return (
    <VStack w="100%" spacing={5} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Heading>{t('title')}</Heading>

        <Specifications
          maxDuration={props.maxDuration}
          maxParticipants={props.maxParticipants}
        />
      </HStack>

      {
        props.isLoading ? (
          <VStack w="100%" pt="4px" spacing={7}>
            {
              [5, 5, 3].map((noOfLines, i) =>
                <SkeletonText
                  key={i}
                  display="flex"
                  flexDirection="column"
                  w="100%"
                  skeletonHeight="10px"
                  gap="4px"
                  noOfLines={noOfLines}
                />
              )
            }
          </VStack>
        ) : (
          <VStack w="100%" spacing={3} alignItems="flex-start">
            <Text dangerouslySetInnerHTML={{ __html: sanitize((props.description.content || '').trim()) }} whiteSpace="pre-line" />

            {
              props.description.isGenerated &&
                <Tooltip label={t('generatedContent.tooltip')} whiteSpace="pre-line" minW="500px" size="md">
                  <HStack spacing={2} cursor="default">
                    <Icon icon="sparkles" size={4} color="gray.400" />
                    <Text color="gray.400">{t('generatedContent.label')}</Text>
                  </HStack>
                </Tooltip>
            }
          </VStack>
        )
      }

      { hasHightlights && <Highlights type={props.type} items={props.highlights} /> }
    </VStack>
  );
}