import { BoxProps } from '@chakra-ui/react';
import { PlaceJSON } from '@jurnee/common/src/entities/Place';
import { useMemo } from 'react';
import { ExperienceCard } from '.';

interface Props {
  place: PlaceJSON;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

export function Place({ place, onMouseEnter, onMouseLeave }: Props & Pick<BoxProps, 'onClick'>) {
  const imageUrl = useMemo(() => place.photos.length > 0 ? place.photos[0].path : '', [place]);
  const address = useMemo(() => place.formattedAddress.split(','), [place]);
  const location = useMemo(() => address.splice(-2)[0], [address]);

  return <ExperienceCard
    src={imageUrl}
    to={`/places/${place.id}`}
    title={place.name}
    rating={place.rating}
    address={location}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  />;
}