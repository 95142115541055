import { formatPriceInCurrency } from '@jurnee/common/src/utils/prices';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'src/store/user/user.selectors';

interface Props {
  value: number;
  currency: string;
  fractionDigits?: number;
}

export function PriceInUserCurrency({ value, currency, fractionDigits }: Props): string {
  const user = useSelector(getUserSelector);

  return formatPriceInCurrency({
    value,
    currency,
    targetCurrency: user.currency,
    fractionDigits
  });
}