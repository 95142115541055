import { Skeleton, VStack } from '@chakra-ui/react';
import { SkeletonText } from '@jurnee/common/src/components/Skeleton/SkeletonText';

function getText(width: string) {
  return <SkeletonText w={width} h="16px" pt="3px" skeletonHeight="10px" />;
};

export function ExperienceCardSkeleton () {
  return (
    <VStack w="100%" spacing={3} alignItems="flex-start">
      <Skeleton
        w="100%"
        style={{ aspectRatio: '1' }}
        borderRadius={8}
        variant="lightGray"
      />

      <VStack w="100%" spacing={2} alignItems="flex-start">
        { getText('90%') }
        { getText('60%') }
      </VStack>

      { getText('35%') }
    </VStack>
  );
}