import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';

interface Props {
  maxDuration: number;
  maxParticipants: number;
}

export function Specifications({ maxDuration, maxParticipants }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'specs' });

  return (
    <HStack spacing={8}>
      {
        maxDuration > 0 &&
          <HStack spacing={2}>
            <Icon icon="time" />
            <Text fontSize={14}>
              { t('durationInMin', { maxDuration }) }
            </Text>
          </HStack>
      }
      {
        maxParticipants > 0 &&
          <HStack spacing={2}>
            <Icon icon="group" />
            <Text fontSize={14}>
              { t('participants', { maxParticipants }) }
            </Text>
          </HStack>
      }
    </HStack>
  );
}