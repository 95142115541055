import { Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AddressJSON } from '../../../entities/Address';
import { formatAddress } from '../../../utils/addresses';
import { StaticMap } from '../../StaticMap';

interface Props {
  address: Pick<AddressJSON, 'address' | 'city' | 'country' | 'postalCode' | 'lat' | 'long'> & { additionalInformation?: string, description?: string };
}

export function ExperienceMap({ address }: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.map' });

  return (
    <>
      <Divider />

      <VStack w="100%" spacing={5} alignItems="flex-start">
        <HStack justifyContent="space-between" w="100%">
          <Heading>{t('title')}</Heading>

          <Text>{formatAddress(address)}</Text>
        </HStack>

        <StaticMap
          zoom={14}
          width={1120}
          height={420}
          overlay='pin-s+4747E6'
          long={address.long}
          lat={address.lat}
        />

        { address.description && <Text fontSize={14}>{address.description}</Text> }
      </VStack>
    </>
  );
}