import { Divider, VStack } from '@chakra-ui/react';
import { ExperienceJSON } from '../../../entities/Experience';
import { PartnerProviderInformationJSON } from '../../../entities/PartnerProviderInformation';
import { isEmpty } from '../../../utils/strings';
import { Content } from './Content';
import { Included } from './Included';
import { PartnerInfo } from './PartnerInfo';
import { Requirements } from './Requirements';

interface Props {
  description: { content: string, isGenerated?: boolean };
  maxDuration: number;
  maxParticipants: number;
  type: ExperienceJSON['type'];
  highlights: string[];
  included: string[];
  requirements: string[];
  partnerImageUrl: string;
  partnerName: string;
  partnerDescription: string;
  partnersProvidersInformation: Pick<PartnerProviderInformationJSON, 'ratingAverage' | 'ratingCount'>[];
  isLoading?: boolean;
}

export function ExperienceDescription(props: Props) {
  const hasContent = props.isLoading || props.description && !isEmpty(props.description.content);
  const hasIncluded = props.included.length > 0;
  const hasRequirements = props.requirements.length > 0;
  const hasPartnerInfoDivider = hasContent || hasIncluded || hasRequirements;

  return (
    <VStack w="100%" spacing={8}>
      {
        hasContent &&
          <Content
            description={props.description}
            maxDuration={props.maxDuration}
            maxParticipants={props.maxParticipants}
            type={props.type}
            highlights={props.highlights}
            isLoading={props.isLoading}
          />
      }
      { hasIncluded && <Included items={props.included} /> }
      { hasRequirements && <Requirements items={props.requirements} /> }
      {
        props.partnerName &&
          <>
            { hasPartnerInfoDivider && <Divider /> }
            <PartnerInfo
              imageUrl={props.partnerImageUrl}
              name={props.partnerName}
              description={props.partnerDescription}
              partnersProvidersInformation={props.partnersProvidersInformation}
            />
          </>
      }
    </VStack>
  );
}