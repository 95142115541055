import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { PartnerProviderInformationJSON } from '@jurnee/common/src/entities/PartnerProviderInformation';
import { useTranslation, withTranslation } from 'react-i18next';
import { ExperienceRatingJSON } from '../../../entities/Experience';
import { RatingStars } from '../../RatingStars';
import { PartnerProviderAvgRatings } from './PartnerProviderAvgRatings';
import { ReviewsChart } from './ReviewsChart';

interface Props {
  rating: Pick<ExperienceRatingJSON, 'average' | 'count'>;
  reviewsCountGroupByRating?: Record<number, number>;
  partnersProvidersInformation?: PartnerProviderInformationJSON[];
}

export function ReviewsSummary(props: Props) {
  const { t } = useTranslation('reviews');

  return (
    <VStack alignItems="flex-start" w="100%" p={5} bg="white" borderRadius={8} border="1px solid" borderColor="blue.50" display="inline-block">
      <HStack alignItems="strech" spacing={2}>
        <Heading lineHeight="40px" size="xxl">{props.rating.average.toFixed(1)}</Heading>
        <VStack alignItems="flex-start">
          <RatingStars rating={props.rating.average} />
          <Text fontSize={14} lineHeight="14px" color="gray.400">{t('reviewsCount', { count: props.rating.count })}</Text>
        </VStack>
      </HStack>

      {
        props.reviewsCountGroupByRating &&
          <ReviewsChart
            reviewsCountGroupByRating={props.reviewsCountGroupByRating}
            ratingCount={props.rating.count}
          />
      }

      {
        props.partnersProvidersInformation &&
          <PartnerProviderAvgRatings partnersProvidersInformation={props.partnersProvidersInformation} />
      }
    </VStack>
  );
}

export default withTranslation('booking')(ReviewsSummary);