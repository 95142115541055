import { Box, FormLabel, Text, VStack } from '@chakra-ui/react';
import RadioCard from '@jurnee/common/src/components/RadioCard';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { formatPriceInCurrency } from '@jurnee/common/src/utils/prices';
import { getPackagePrice, getProductPrice, isCustomRequestProduct, sortByPackagePrice } from '@jurnee/common/src/utils/products';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { UserDetails } from '../entities/User';

interface OwnProps extends WithTranslation {
  currentProductId: ProductJSON['id'];
  products: ProductJSON[];
  quantity: number;
  targetCurrency: UserDetails['currency'];
  onChange(productId: ProductJSON['id']): void;
}

class PackageRadioGroup extends React.Component<OwnProps> {

  isSelected = ({ id }: Pick<ProductJSON, 'id'>) => {
    return this.props.currentProductId === id;
  };

  getDescription = ({ description }: Pick<ProductJSON, 'description'>) => {
    if (isEmpty(description)) {
      return null;
    }

    return <Text w="100%" color="gray.500">{description}</Text>;
  };

  get radioCards() {
    return sortByPackagePrice(this.props.products, this.props.quantity).map(product => {
      const label = isEmpty(product.name) ? this.props.t('form.steps.information.package.customRequest.label') : product.name;
      const packagePrice = getPackagePrice(product, this.props.quantity);
      const price = formatPriceInCurrency({ value: packagePrice, currency: product.currency, targetCurrency: this.props.targetCurrency });

      const labelInfo =
        isCustomRequestProduct(product) ? (
          <Text fontSize={14} textAlign="right" minW="130px">
            { this.props.t('form.steps.information.package.customRequest.labelInfo') }
          </Text>
        ) : (
          <Text fontSize={14} textAlign="right" minW="85px">
            { price }
          </Text>
        );

      return (
        <RadioCard
          key={product.id}
          isSelected={this.isSelected(product)}
          onSelect={() => this.props.onChange(product.id)}
          label={label}
          labelInfo={labelInfo}
          isInvalid={this.props.quantity > 0 && getProductPrice(product, this.props.quantity) === null}
        >
          { this.getDescription(product) }
        </RadioCard>
      );
    });
  }

  render() {
    return (
      <Box w="100%">
        <FormLabel>{this.props.t('form.steps.information.package.label')}</FormLabel>

        <VStack alignItems="flex-start" spacing="10px">
          { this.radioCards }
        </VStack>
      </Box>
    );
  }

}

export default withTranslation('experience')(PackageRadioGroup);