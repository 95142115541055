import { Box, Divider, Heading, VStack } from '@chakra-ui/react';
import { PartnerProviderInformationJSON } from '@jurnee/common/src/entities/PartnerProviderInformation';
import { useTranslation } from 'react-i18next';
import { ReviewsDrawer } from '../../drawers/ReviewsDrawer';
import { ExperienceRatingJSON } from '../../entities/Experience';
import { ReviewCard, ReviewCardProps } from '../reviews/ReviewCard';
import { ReviewsSummary } from '../reviews/ReviewsSummary';

interface Props {
  rating: Pick<ExperienceRatingJSON, 'average' | 'count'>;
  reviewsCountGroupByRating?: Record<number, number>;
  reviews: ReviewCardProps[];
  partnersProvidersInformation?: PartnerProviderInformationJSON[];
}

export function ExperienceReviews(props: Props) {
  const { t } = useTranslation('experience');

  const hasReviewsDrawer = props.reviews.length > 5;

  return (
    <>
      <Divider />

      <VStack w="100%" spacing={5} alignItems="flex-start">
        <Heading size="md">{t('details.reviews.title')}</Heading>
        <Box w="100%" sx={{ columnCount: [2], columnGap: 5 }}>
          <Box mb={5}>
            <ReviewsSummary
              rating={props.rating}
              reviewsCountGroupByRating={props.reviewsCountGroupByRating}
              partnersProvidersInformation={props.partnersProvidersInformation}
            />
          </Box>
          {
            props.reviews.slice(0,5).map((review, index) =>
              <Box key={index} mb={index === 4 ? 0 : 5}>
                <ReviewCard {...review} />
              </Box>
            )
          }
        </Box>
      </VStack>

      { hasReviewsDrawer && <ReviewsDrawer reviews={props.reviews} /> }
    </>
  );
}