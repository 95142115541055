import { VStack } from '@chakra-ui/react';
import { ProgressBar } from './ProgressBar';

interface Props {
  reviewsCountGroupByRating: Record<number, number>;
  ratingCount: number;
}

export function ReviewsChart(props: Props) {
  return (
    <VStack w="100%" spacing={2} mt={5}>
      {
        ...[5, 4, 3, 2, 1].map((value) => {
          const count = props.reviewsCountGroupByRating[value] || 0;
          const percentage = Math.floor(count / props.ratingCount * 100);

          return <ProgressBar value={value} percentage={percentage} count={count} />;
        })
      }
    </VStack>
  );
}