import { Box } from '@chakra-ui/react';
import { getCdnImageUrl } from '../../../../utils/core';

interface Props {
  imageUrl?: string;
}

export function PartnerPhoto({ imageUrl }: Props) {
  const imageProps = imageUrl ? {
    bgImage: `url('${imageUrl.startsWith('https') ? imageUrl : getCdnImageUrl(imageUrl)}');`
  } : null;

  return (
    <Box
      minW="60px"
      minH="60px"
      bgColor="rgb(30, 0, 45)"
      bgPosition="center"
      bgSize="cover"
      alignSelf="stretch"
      borderRadius="4px"
      {...imageProps}
    />
  );
}