import { Heading, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  importantInformation: string[];
  wheelchairAccessible: boolean;
  pregnantAccessible: boolean;
}

export function ImportantInfo(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.importantInfo' });

  return (
    <VStack w="50%" spacing={5} alignItems="flex-start">
      <Heading>{t('title')}</Heading>

      <UnorderedList spacing={4}>
        {
          props.importantInformation.map((item, index) => (
            <ListItem key={index} fontSize={14}>{item}</ListItem>
          ))
        }

        <ListItem fontSize={14}>{t(props.wheelchairAccessible ? 'wheelchairAccessible' : 'notWheelchairAccessible')}</ListItem>

        {
          !props.pregnantAccessible &&
            <ListItem fontSize={14}>{t('notPregnantAccessible')}</ListItem>
        }
      </UnorderedList>
    </VStack>
  );
}