import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FromDatePicker } from '../../../FromDatePicker';
import { ParticipantsInput } from '../../../ParticipantsInput';

interface Props {
  participants: number;
  maxParticipants: number;
  areParticipantsValid: boolean;
  date: Date;
  bookingDeadline?: number;
  onParticipantsChange(participants: number): void;
  onDateChange(date: Date): void;
}

export function BoxShippingFields(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'form.steps.information' });

  return (
    <HStack w="100%" spacing={5}>
      <ParticipantsInput
        size="sm"
        label={t('quantity.label')}
        defaultParticipants={props.participants}
        maxParticipants={props.maxParticipants}
        onChange={props.onParticipantsChange}
        isParticipantsRangeValid={props.areParticipantsValid}
        isRequired
      />

      <FromDatePicker
        label={t('dateApprox.label')}
        bookingDeadline={props.bookingDeadline}
        selected={props.date}
        onChange={props.onDateChange}
        inputProps={{ size: 'sm' }}
        isRequired
      />
    </HStack>
  );
}