import { Box, VStack, useToast } from '@chakra-ui/react';
import { CommentCard } from '@jurnee/common/src/components/comments/CommentCard';
import { CommentForm, CommentFormData } from '@jurnee/common/src/components/comments/CommentForm';
import { SkeletonComment } from '@jurnee/common/src/components/Skeleton/SkeletonComment';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { TaskCommentJSON } from '@jurnee/common/src/entities/TaskComment';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createTaskComment, getTaskComments } from 'src/api/tasks';

interface Props {
  task: TaskJSON;
}

export function Comments(props: Props) {
  const toast = useToast();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('tasks', { keyPrefix: 'drawer' });

  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState<TaskCommentJSON[]>([]);
  const [users, setUsers] = useState<UserJSON[]>([]);

  const sortedComments = useMemo(() => sortByDate(comments, 'createdAt'), [comments]);

  async function fetchTaskComments() {
    const { list, relationships: { users } } = await getTaskComments({
      bookingId: props.task.bookingId,
      taskId: props.task.id
    });

    setComments(list);
    setUsers(users);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchTaskComments();
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
  }, [comments, scrollRef.current]);

  function refScroll(instance: HTMLDivElement) {
    scrollRef.current = instance;
  }

  async function onSubmit({ content }: CommentFormData) {
    try {
      const { data, relationships } = await createTaskComment(
        {
          bookingId: props.task.bookingId,
          taskId: props.task.id
        },
        { content }
      );

      setComments([...comments, data]);
      setUsers([...users.filter(user => !relationships.users.map(({ id }) => id).includes(user.id)), ...relationships.users]);
    } catch(error) {
      toast(getErrorToast(t('toasts.createComment.error')));
    }
  }

  return (
    <VStack
      w="100%"
      h="100%"
      p={5}
      spacing={5}
      justifyContent="space-between"
      overflowY="auto"
    >
      <VStack w="100%" spacing={5}>
        {
          isLoading ? (
            <VStack w="100%" spacing={5}>
              <SkeletonComment />
              <SkeletonComment />
            </VStack>
          ) : (
            sortedComments.map(taskComment => {
              const user = users.find(user => user.id === taskComment.userId);
              return <CommentCard key={taskComment.id} comment={taskComment} user={user} />;
            })
          )
        }
      </VStack>

      <Box w="100%" ref={refScroll}>
        <CommentForm onSubmit={onSubmit} />
      </Box>
    </VStack>
  );
}