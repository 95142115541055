import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isRequired: boolean;
  onChange(value: string): void;
}

export function CommentTextarea(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'form.steps.event.comment' });

  function onChange({ target }: React.ChangeEvent<HTMLTextAreaElement>) {
    props.onChange(target.value);
  }

  return (
    <FormControl isRequired={props.isRequired}>
      <FormLabel>{t(props.isRequired ? 'labelRequired' : 'label')}</FormLabel>

      <Textarea
        size="sm"
        height="100px"
        resize="none"
        placeholder={t(props.isRequired ? 'placeholderRequired' : 'placeholder')}
        onChange={onChange}
      />
    </FormControl>
  );
}