import { Text } from '@chakra-ui/react';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { getProductWithMinUnitPrice, isCustomRequestProduct } from '@jurnee/common/src/utils/products';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceInUserCurrency } from 'src/components/PriceInUserCurrency';

interface Props {
  products?: ProductJSON[];
}

export function Price(props: Props) {
  const { t } = useTranslation('experience');

  const products = useMemo(() => props.products ? props.products.filter(product => !isCustomRequestProduct(product)) : [], [props.products]);
  const product = useMemo(() => getProductWithMinUnitPrice(products), [products]);

  const isPriceUponRequest = !product?.minPricePerUnit || !products || products.length === 0;

  if (isPriceUponRequest) {
    return (
      <Text color="gray.400" lineHeight="16px" noOfLines={1}>
        { t('price.priceUponRequest') }
      </Text>
    );
  }

  return (
    <Text fontWeight={700} lineHeight="16px" noOfLines={1}>
      <PriceInUserCurrency value={product.minPricePerUnit} currency={product.currency} /> { t('price.perPers') }
    </Text>
  );
}