import { Text } from '@chakra-ui/react';
import { formatPriceInCurrency } from '@jurnee/common/src/utils/prices';
import { Trans, useTranslation } from 'react-i18next';
import { ProductJSON } from '../../../../entities/Product';
import { UserDetails } from '../../../../entities/User';
import { getMaxThreshold, getProductPrice, isCustomRequestProduct, isParticipantsRangeValid } from '../../../../utils/products';

interface Props {
  product: ProductJSON;
  participants: number;
  targetCurrency: UserDetails['currency'];
}

export function PriceLine(props: Props) {
  const { t } = useTranslation('common');

  if (!isParticipantsRangeValid(props.product, props.participants)) {
    return (
      <Text lineHeight="26px" color="red.500">
        <Trans
          i18nKey='experience:form.steps.information.participants.error'
          values={{ min: 1, max: getMaxThreshold(props.product) }}
        />
      </Text>
    );
  }

  if (isCustomRequestProduct(props.product)) {
    return null;
  }

  const value = getProductPrice(props.product, props.participants);
  const price = formatPriceInCurrency({ value, currency: props.product.currency, targetCurrency: props.targetCurrency });

  return (
    <>
      <Text fontSize={20} fontWeight={700}>
        { price }
      </Text>

      <Text lineHeight="24px" color="gray.400" h={5}>
        { t('excludedTax') }
      </Text>
    </>
  );
}