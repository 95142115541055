import { Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExperienceJSON } from '../../../../entities/Experience';

interface Props {
  type: ExperienceJSON['type'];
  items: string[];
}

function getLabel(type: ExperienceJSON['type']) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.description' });

  switch (type) {
  case 'RESTAURANT':
    return t('cuisines');
  case 'BAR':
    return t('categories');
  default:
    return t('highlights');
  }
}

export function Highlights({ type, items }: Props) {
  const title = getLabel(type);

  return (
    <>
      <Heading size="xs">{title}</Heading>

      <UnorderedList spacing={4}>
        {
          items.map((highlight, i) => (
            <ListItem key={i} fontSize={14}>{highlight}</ListItem>
          ))
        }
      </UnorderedList>
    </>
  );
}