import { HStack, Text } from '@chakra-ui/react';
import { ExperienceAverageRating } from '@jurnee/common/src/components/experiences/ExperienceAverageRating';
import { ExperienceRatingJSON } from '@jurnee/common/src/entities/Experience';
import { useTranslation } from 'react-i18next';

interface Props {
  rating: Pick<ExperienceRatingJSON, 'average' | 'count'>;
  address: string;
  isGeneric?: boolean;
}

export function Subtitle({ rating, address, isGeneric }: Props) {
  const { t } = useTranslation('experience');

  if (isGeneric) {
    return (
      <Text color={'gray.400'} lineHeight="16px">
        { t('specs.customRequestInspiration') }
      </Text>
    );
  }

  return (
    <HStack spacing={1}>
      <ExperienceAverageRating rating={rating} />

      { address && <Text fontWeight={500} lineHeight="16px" noOfLines={1}>· {address}</Text> }
    </HStack>
  );
}