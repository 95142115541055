import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { useTranslation } from 'react-i18next';
import EmptyState from 'src/components/EmptyState';
import { PrimaryButton } from 'src/components/buttons';
import { CustomItemModal } from 'src/modals/CustomItemModal';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { ExperienceCategory } from 'src/utils/experiences';

interface Props {
  type: ExperienceJSON['type'];
  bookingOrigin: BookingJSON;
}

export function NoResultsCard(props: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('experiences', { keyPrefix: 'noResults' });

  const type = ['RESTAURANT', 'BAR'].find((type) => props.type === type) ?? 'CUSTOM_REQUEST';

  function onClick() {
    dispatch(trackEvent({ name: `empty_state_${props.type.toLowerCase()}_button_clicked` }));
  }

  return (
    <EmptyState
      imagePath="/assets/illustrations/search.svg"
      heading={t('heading')}
      description={t('description')}
    >
      <CustomItemModal
        type={type as ExperienceCategory['type']}
        booking={props.bookingOrigin}
      >
        <PrimaryButton size="sm" onClick={onClick}>
          { t('cta') }
        </PrimaryButton>
      </CustomItemModal>
    </EmptyState>
  );
}