import { cssVar, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import { Avatar } from './avatar';
import { Badge } from './badge';
import { Button } from './button';
import { Card } from './card';
import { Checkbox } from './checkbox';
import { colors } from './colors';
import { Drawer } from './drawer';
import { Form } from './form';
import { FormLabel } from './formLabel';
import { Heading } from './heading';
import { Input } from './input';
import { List } from './list';
import { Menu } from './menu';
import { Modal } from './modal';
import { NumberInput } from './numberInput';
import { Select } from './select';
import { Table } from './table';
import { Tabs } from './tabs';
import { Text } from './text';
import { Textarea } from './textarea';
import { Tooltip } from './tooltip';

const tagGray = defineStyle({
  container: {
    borderRadius: '2px',
    bgColor: 'gray.500',
    minHeight: '16px',
    height: '16px',
    px: '6px'
  },
  label: {
    textTransform: 'uppercase',
    color: 'white',
    fontSize: '11px',
    fontWeight: 700,
    lineHeight: '16px'
  },
  closeButton: {
    opacity: 1,
    color: 'white',
    fontSize: '14px',
    marginInlineStart: 0
  }
});

const tagTheme = defineStyleConfig({
  variants: {
    gray: tagGray
  }
});

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const skeletonLightGray = defineStyle({
  _light: {
    [$startColor.variable]: 'colors.gray.100',
    [$endColor.variable]: 'colors.gray.200',
  },
});

const skeletonTheme = defineStyleConfig({
  variants: { lightGray: skeletonLightGray }
});

export default extendTheme({
  styles: {
    global: {
      body: {
        fontSize: '14px'
      }
    }
  },
  breakpoints: {
    sm: '48em',
    md: '64em',
    lg: '80em',
    xl: '90em',
    '2xl': '120em',
    '3xl': '138em'
  },
  fonts: {
    body: 'Inter, Arial, sans-serif',
    heading: 'Inter, Arial, sans-serif'
  },
  components: {
    Avatar,
    Badge,
    Button,
    Card,
    Checkbox,
    Divider: {
      baseStyle: {
        borderColor: 'blue.50',
        opacity: 1
      }
    },
    Drawer,
    Form,
    FormLabel,
    Heading,
    Input,
    List,
    Menu,
    Modal,
    NumberInput,
    Radio: {
      variants: {
        radioCard: {
          label: {
            width: '100%'
          }
        }
      }
    },
    Select,
    Skeleton: skeletonTheme,
    Table,
    Tabs,
    Tag: tagTheme,
    Text,
    Textarea,
    Tooltip
  },
  colors,
  shadows: {
    outline: 'none'
  }
});