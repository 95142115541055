import { HStack, VStack } from '@chakra-ui/react';
import { AmountInputData } from '@jurnee/common/src/components/AmountInput';
import { BudgetSelect } from '@jurnee/common/src/components/BudgetSelect';
import { ParticipantsInput } from '@jurnee/common/src/components/ParticipantsInput';
import { BookingCreateBody, PartnerDocumentCreateBody } from '@jurnee/common/src/dtos/bookings';
import { PlaceAddressDTO, PlaceDetailsDTO } from '@jurnee/common/src/dtos/places';
import { PropositionsGroupCreateBody } from '@jurnee/common/src/dtos/propositionsGroups';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { convertToTimezone, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { computePayViaJurneePricing, PAY_VIA_JURNEE_TARGET_CURRENCY, PayViaJurneePricing } from '@jurnee/common/src/utils/prices';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { setHoursFromTime } from 'src/utils/date';
import { NameInput } from '../../../components/Booking/NameInput';
import { LocationSelector } from '../../../components/BookingItem/LocationSelector';
import { ProviderSelector } from '../../../components/BookingItem/ProviderSelector';
import { DateTimeSelector } from './DateTimeSelector';
import { QuoteFileInput } from './QuoteFileInput';
import { ServiceDescriptionInput } from './ServiceDescriptionInput';
import { TotalAmountInput } from './TotalAmountInput';

interface QuoteFormData {
  amount: PartnerDocumentCreateBody['amount'];
  currencyId: string;
  file: File;
}

export interface PayViaJurneeFormData {
  propositionsGroup: PropositionsGroupCreateBody;
  quote: QuoteFormData;
  providerPlaceId: string;
  booking?: Pick<BookingCreateBody, 'name' | 'budgetId'>;
}

interface Props {
  sessionToken: string;
  booking: BookingJSON;
  budgets: BudgetJSON[];
  currencies: CurrencyExchangeRates;
  onAmountChange(data: PayViaJurneePricing): void;
  onSubmit(data: PayViaJurneeFormData): Promise<void>;
  onClose(): void;
}

export function Form(props: Props) {
  const { t } = useTranslation(['common', 'booking']);

  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [name, setName] = useState<string>(null);
  const [budgetId, setBudgetId] = useState<number>(null);

  const [isVirtual, setIsVirtual] = useState(false);
  const [cityAddress, setCityAddress] = useState<PlaceAddressDTO>(null);
  const [partnerPlace, setPartnerPlace] = useState<Partial<PlaceDetailsDTO>>(null);

  const [date, setDate] = useState<Date>(null);
  const [time, setTime] = useState<string>('');

  const [participants, setParticipants] = useState<number>(null);
  const [description, setDescription] = useState<string>(null);

  const [amount, setAmount] = useState<number>(null);
  const [currencyId, setCurrencyId] = useState<string>(null);

  const [file, setFile] = useState<File>(null);

  const isDescriptionInvalid = description !== null && isEmpty(description);
  const isParticipantsInvalid = participants !== null && participants <= 0;
  const isAmountInvalid = amount !== null && amount <= 0;

  function isSubmitDisabled() {
    if (isDescriptionInvalid) {
      return true;
    }

    if (isParticipantsInvalid) {
      return true;
    }

    if (isAmountInvalid) {
      return true;
    }

    if (isEmpty(time)) {
      return true;
    }

    if (!props.booking && !name) {
      return true;
    }

    return [
      amount,
      date,
      description,
      participants,
      partnerPlace,
      file
    ].includes(null);
  }

  async function onSubmit() {
    setIsSaving(true);
    setIsSubmitted(true);

    const dateTime = setHoursFromTime(date, time);
    const dateUTC = convertToTimezone(dateTime, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);

    const propositionsGroup: PropositionsGroupCreateBody = {
      content: description,
      from: dateUTC,
      participants
    };

    if (props.booking) {
      await props.onSubmit({ propositionsGroup, quote: { amount, currencyId, file }, providerPlaceId: partnerPlace.id });
    } else {
      await props.onSubmit({ booking: { name, budgetId }, propositionsGroup, quote: { amount, currencyId, file }, providerPlaceId: partnerPlace.id });
    }

    setIsSaving(false);
    setIsSubmitted(false);
  }

  function onCityChange(placeAddress: PlaceAddressDTO) {
    setCityAddress(placeAddress);
    setPartnerPlace(null);
  }

  function onAmountChange(data: AmountInputData) {
    const pricing = computePayViaJurneePricing(data, PAY_VIA_JURNEE_TARGET_CURRENCY, props.currencies);

    setAmount(data.amount);
    setCurrencyId(data.currency);

    props.onAmountChange(pricing);
  }

  return (
    <VStack w="100%" spacing={4} justifyContent="space-between">
      <VStack w="100%" spacing={4}>
        {
          !props.booking &&
            <HStack w="100%" spacing={5}>
              <NameInput
                defaultValue={props.booking?.name}
                isInvalid={isSubmitted && isEmpty(name)}
                onChange={setName}
              />

              {
                props.budgets.length > 0 &&
                  <BudgetSelect
                    size="sm"
                    label={t('common:fields.budget.label')}
                    placeholder={t('common:fields.budget.placeholder')}
                    budgets={props.budgets}
                    onChange={setBudgetId}
                  />
              }
            </HStack>
        }

        <LocationSelector
          type="SPECIFIC_PARTNER"
          isVirtual={isVirtual}
          onCityChange={onCityChange}
          onVirtualChange={setIsVirtual}
        />

        <ProviderSelector
          sessionToken={props.sessionToken}
          cityAddress={cityAddress}
          partnerPlace={partnerPlace}
          onChange={setPartnerPlace}
        />

        <HStack w="100%" spacing={5}>
          <ServiceDescriptionInput
            isInvalid={isDescriptionInvalid}
            onChange={setDescription}
          />

          <ParticipantsInput
            label={t('common:fields.participants.label')}
            onChange={setParticipants}
            isParticipantsRangeValid={!participants || participants > 0}
            isRequired={true}
          />
        </HStack>

        <DateTimeSelector
          date={date}
          onDateChange={setDate}
          onTimeChange={setTime}
        />

        <TotalAmountInput
          currencies={Object.keys(props.currencies)}
          onChange={onAmountChange}
          isInvalid={isAmountInvalid}
        />

        <QuoteFileInput onChange={setFile} />
      </VStack>

      <HStack w="100%" spacing={5} mt={1}>
        <SecondaryButton
          w="50%"
          size="sm"
          colorScheme="gray"
          onClick={props.onClose}
        >
          { t('common:buttons.close') }
        </SecondaryButton>

        <PrimaryButton
          w="50%"
          size="sm"
          colorScheme="black"
          onClick={onSubmit}
          isDisabled={isSubmitDisabled()}
          isLoading={isSaving}
        >
          { t('booking:modals.payViaJurnee.submit') }
        </PrimaryButton>
      </HStack>
    </VStack>
  );
}