import { HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { getUserBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { PriceInUserCurrency } from 'src/components/PriceInUserCurrency';

type UsersOutBudgetProps = {
  individualBudget: number;
  userBudgetBreakdowns: UserBudgetBreakdownJSON[];
  users: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  currency: Currency;
}

export function UsersOutBudget({ individualBudget, userBudgetBreakdowns, users, currency }: UsersOutBudgetProps) {
  return (
    <VStack w="100%" spacing={2}>
      {
        userBudgetBreakdowns.map(userBudgetBreakdown => {
          const user = users.find(user => user.id === userBudgetBreakdown.userId);
          const remaining = getUserBudgetRemaining(userBudgetBreakdown) - individualBudget;

          return (
            <HStack key={user.id} w="100%" spacing="10px">
              <Avatar user={user} borderRadius={4} />
              <Text>{getUserLabel(user)}</Text>
              <Spacer />
              <Text color="red.500">
                <PriceInUserCurrency value={remaining} currency={currency} />
              </Text>
            </HStack>
          );
        })
      }
    </VStack>
  );
}