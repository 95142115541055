import { FormControl, FormLabel } from '@chakra-ui/react';
import { TimeSlot, TimeSlotSelector } from '@jurnee/common/src/components/TimeSlotSelector';
import { useTranslation } from 'react-i18next';
import { DEFAULT_BOOKING_DURATION } from '../../../../utils/bookings';

interface Props {
  date: Date;
  minDuration?: number;
  isRequired?: boolean;
  onChange(slot: TimeSlot): void;
}

export function TimeSlotSelect(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'form.steps.information.time' });

  return (
    <FormControl id="time" isRequired={props.isRequired}>
      <FormLabel>{t('label')}</FormLabel>

      <TimeSlotSelector
        size="sm"
        date={props.date || new Date()}
        duration={props.minDuration || DEFAULT_BOOKING_DURATION}
        placeholder={t('placeholder')}
        onPick={props.onChange}
      />
    </FormControl>
  );
}