import {
  Box,
  Heading,
  HStack,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { CollectionExperience, CollectionExperienceJSON } from '@jurnee/common/src/entities/CollectionExperience';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { getExperienceMaxParticipants, getExperiencePath } from '@jurnee/common/src/utils/experiences';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Price } from 'src/components/Experience/ExperienceCard/Price';
import RemoveFromCollectionModal from 'src/modals/RemoveFromCollectionModal';

interface Props {
  collectionExperience: CollectionExperienceJSON;
  onRemoveFromCollection(data: CollectionExperience): void;
}

export function CollectionExperienceItem(props: Props) {
  const { experience } = props.collectionExperience;
  const imageUrl = useMemo(() => experience.experiencesImages.length > 0 ? getCdnImageUrl(experience.experiencesImages[0].image.path) : '', [experience]);

  return (
    <LinkBox w="100%">
      <HStack spacing={0} w="100%" bgColor="white" border="1px solid" borderColor="blue.50" borderRadius={4} overflow="hidden">
        <Box
          minW={160}
          h={100}
          bg={`linear-gradient(to top, rgba(50, 10, 100, 0.5) 0%, rgba(200, 0, 200, 0.1) 100%), url('${imageUrl}');`}
          bgPosition="center"
          bgSize="cover"
        />

        <VStack spacing={3} p={4} w="100%" align="stretch">
          <HStack>
            <Heading size="md" noOfLines={1}>
              <LinkOverlay to={getExperiencePath(experience)} as={Link}>{experience.name}</LinkOverlay>
            </Heading>
            <Spacer />
            <RemoveFromCollectionModal collectionExperience={props.collectionExperience} onRemove={props.onRemoveFromCollection} />
          </HStack>
          <HStack>
            <HStack>
              <Icon icon="group" color="gray.400" size={4} />
              <Text fontSize="14px" color="gray.400" noOfLines={1}>
                <Trans i18nKey={`experience:specs.participants`} values={{ maxParticipants: getExperienceMaxParticipants(experience)}} />
              </Text>
            </HStack>

            <Spacer />

            <Price products={experience.products} />
          </HStack>
        </VStack>
      </HStack>
    </LinkBox>
  );
}