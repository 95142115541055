import { Grid, GridItem } from '@chakra-ui/react';
import { PhotosDrawer } from '../../../../drawers/PhotosDrawer';
import { ImageJSON } from '../../../../entities/Image';
import { getCdnImageUrl } from '../../../../utils/core';
import { SeePhotosButton } from './SeePhotosButton';

interface Props {
  images: ImageJSON[]
}

function getImageProps(image?: ImageJSON) {
  const props = {
    bgSize: 'cover',
    bgPosition: 'center',
    bgColor: 'gray.200'
  };

  if (!image) {
    return props;
  }

  const src = image.path.startsWith('https') ? image.path : getCdnImageUrl(image.path);

  return {
    ...props,
    bgImage: `url('${src}')`
  };
}

export function ImagesGrid(props: Props) {
  return (
    <Grid w="100%" templateRows="2" templateColumns="14" gap={1} h={400}>
      <GridItem
        rowSpan={2}
        colStart={1}
        colSpan={5}
        borderRadius="8px 0 0 8px"
        {...getImageProps(props.images[0])}
      />

      <GridItem
        rowSpan={1}
        colStart={6}
        colSpan={4}
        {...getImageProps(props.images[1])}
      />

      <GridItem
        rowSpan={1}
        colStart={6}
        colSpan={4}
        rowStart={2}
        {...getImageProps(props.images[2])}
      />

      <GridItem
        rowSpan={2}
        colStart={10}
        colSpan={5}
        borderRadius="0 8px 8px 0"
        position="relative"
        {...getImageProps(props.images[3])}
      >
        {
          props.images.length > 0 &&
            <PhotosDrawer images={props.images}>
              <SeePhotosButton />
            </PhotosDrawer>
        }
      </GridItem>
    </Grid>
  );
}