import { Avatar, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { ReviewJSON } from '@jurnee/common/src/entities/Review';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { isEmpty, sanitize } from '@jurnee/common/src/utils/strings';
import { useTranslation } from 'react-i18next';
import { RatingStars } from '../RatingStars';

export interface ReviewCardProps {
  author: string;
  rating: number;
  comment: string;
  createdAt: string | Date;
  provider?: ReviewJSON['provider'];
}

export function ReviewCard(props: ReviewCardProps) {
  const { t } = useTranslation('reviews');

  const colors = ['teal.400', 'blue.400', 'red.400', 'orange.400', 'pink.400'];
  const avatarBg = colors[props.author[0].toLowerCase().charCodeAt(0) % colors.length];
  const date = formatDate(new Date(props.createdAt));
  const publishedOn = t(props.provider ? `publishedOn_${props.provider}` : 'publishedOn', { date });

  return (
    <VStack w="100%" p={5} bg="white" borderRadius={8} border="1px solid" borderColor="blue.50" alignItems="flex-start" display="inline-block">
      <HStack justifyContent="space-between">
        <HStack>
          <Avatar
            bg={avatarBg}
            color="white"
            size="sm"
            borderRadius={4}
            fontWeight="600"
            name={props.author}
          />
          <Text fontWeight={700}>{props.author}</Text>
        </HStack>
        <RatingStars rating={props.rating} />
      </HStack>

      { !isEmpty(props.comment) && <Text mt={5} fontStyle="italic" dangerouslySetInnerHTML={{ __html: sanitize(props.comment) }} /> }

      <HStack spacing={2} mt={5}>
        { props.provider && <Image w={22} h={22} src={`/assets/images/${props.provider.toLowerCase()}.png`} /> }
        <Text fontSize={14} color="gray.400">{publishedOn}</Text>
      </HStack>
    </VStack>
  );
}