import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import { SkeletonText } from './SkeletonText';

export function SkeletonFileInput() {
  return (
    <HStack
      w="100%"
      p={3}
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
      justifyContent="space-between"
    >
      <VStack spacing={1} alignItems="flex-start">
        <SkeletonText w="150px" h="16px" pt="2px" skeletonHeight="12px" />
        <SkeletonText w="80px" h="14px" pt="2px" skeletonHeight="10px" />
      </VStack>

      <Skeleton w="20px" h="20px" startColor="gray.100" endColor="gray.200" />
    </HStack>
  );
}