import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, SimpleGrid, Text, Textarea, UseToastOptions } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { FromDatePicker } from '@jurnee/common/src/components/FromDatePicker';
import { LanguageSelect } from '@jurnee/common/src/components/LanguageSelect';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ParticipantsInput } from '@jurnee/common/src/components/ParticipantsInput';
import { TimeSlot, TimeSlotSelector } from '@jurnee/common/src/components/TimeSlotSelector';
import { BookingItemUpdateBody } from '@jurnee/common/src/dtos/bookings';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { DEFAULT_BOOKING_DEADLINE } from '@jurnee/common/src/utils/bookings';
import { getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { convertToTimezone, formatTimeShort, getCurrentTimeZone, getDurationInMins } from '@jurnee/common/src/utils/dates';
import { getMaxThreshold, isParticipantsRangeValid } from '@jurnee/common/src/utils/products';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BookingItemUpdatePayload, updateBookingItem } from 'src/store/bookingsItems/bookingsItems.thunks';
import { getLanguagesThunk } from 'src/store/languages/languages.thunks';
import { RootState } from 'src/store/state';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { getZonedTimeSlot } from 'src/utils/date';
import { PrimaryButton, SecondaryButton } from '../components/buttons';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';

interface OwnProps {
  bookingItem: BookingItemJSON;
  experience: ExperienceJSON;
  product: ProductJSON;
  children: React.ReactElement;
}

interface State {
  isOpen: boolean;
  isSubmitted: boolean;
  bookingId: number;
  bookingItem: {
    date: Date;
    timeSlot: TimeSlot;
    participants: number;
    comment: string;
    languageId: number;
  };
}

interface StateProps {
  languages: RootState['languages'];
  user: RootState['user'];
}

interface DispatchProps {
  getLanguagesThunk(): void;
  updateBookingItem(payload: BookingItemUpdatePayload): void;
  showToast(payload: UseToastOptions): void;
  trackEvent(opts: TrackEventOptions): void;
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation;

class EditBookingItemDrawer extends React.PureComponent<Props, State> {

  state: State = this.defaultState;

  get defaultState(): State {
    return {
      isOpen: false,
      isSubmitted: false,
      bookingId: this.props.bookingItem.bookingId,
      bookingItem: {
        date: convertToTimezone(this.props.bookingItem.from, getItemTimezone(this.props.bookingItem), getCurrentTimeZone()),
        timeSlot: this.defaultTimeSlot,
        participants: this.props.bookingItem.participants,
        comment: this.props.bookingItem.comment,
        languageId: this.props.bookingItem.languageId
      }
    };
  }

  onClose = () => {
    this.setState({ ...this.state, isOpen: false });
  };

  onOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.getLanguagesThunk();

    this.setState({ ...this.state, isOpen: true, isSubmitted: false });

    this.props.trackEvent({
      name: 'opened_edit_booking_item_drawer'
    });
  };

  get defaultTimeSlot() {
    const { from, to } = this.props.bookingItem;
    const timezone = getItemTimezone(this.props.bookingItem);

    return {
      from: formatTimeShort(convertToTimezone(from, timezone, getCurrentTimeZone())),
      to: formatTimeShort(convertToTimezone(to, timezone, getCurrentTimeZone()))
    };
  }

  get bookingItem(): BookingItemUpdateBody {
    const { from, to } = getZonedTimeSlot(this.state.bookingItem.date, this.state.bookingItem.timeSlot, getItemTimezone(this.props.bookingItem));

    return {
      comment: this.state.bookingItem.comment,
      from,
      languageId: this.state.bookingItem.languageId,
      participants: this.state.bookingItem.participants,
      to
    };
  }

  updateBookingItem = () => {
    this.props.updateBookingItem({
      bookingId: this.props.bookingItem.bookingId,
      bookingItemId: this.props.bookingItem.id,
      data: this.bookingItem,
      onError: (err) => {
        const title = err instanceof Error ? err.message : this.props.t('drawers.editBookingItem.toasts.editItem.error');
        this.props.showToast({ title, status: 'error' });
      },
      onSuccess: () => {
        this.onClose();
        this.props.showToast({ title: this.props.t('drawers.editBookingItem.toasts.success'), status: 'success' });
      }
    });
  };

  onSubmit = () => {
    this.setState({ ...this.state, isSubmitted: true });

    this.updateBookingItem();
  };

  onDateChange = (date: Date) => {
    if (!date) {
      return;
    }

    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, date } });
  };

  onParticipantsChange = (participants: number) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, participants } });
  };

  onTimeChange = (timeSlot: TimeSlot) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, timeSlot } });
  };

  onLanguageChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, languageId: Number(target.value) } });
  };

  onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, comment: e.target.value } });
  };

  get fromDatePicker() {
    return (
      <FromDatePicker
        bookingDeadline={DEFAULT_BOOKING_DEADLINE}
        selected={this.state.bookingItem.date}
        label={this.props.t('drawers.editBookingItem.date.label')}
        onChange={this.onDateChange}
        inputProps={{ size: 'sm' }}
      />
    );
  }

  get participantsInput() {
    return (
      <ParticipantsInput
        label={this.props.t('fields.participants.label', { ns: 'common' })}
        defaultParticipants={this.state.bookingItem.participants}
        onChange={this.onParticipantsChange}
        isParticipantsRangeValid={this.state.bookingItem.participants > 0}
      />
    );
  }

  get timeSelect() {
    const duration = getDurationInMins(this.props.bookingItem.from, this.props.bookingItem.to);

    return (
      <FormControl id="time">
        <FormLabel>{this.props.t('drawers.editBookingItem.time.label')}</FormLabel>
        <TimeSlotSelector
          size="sm"
          date={this.state.bookingItem.date}
          value={this.state.bookingItem.timeSlot.from}
          duration={duration}
          onPick={this.onTimeChange}
        />
      </FormControl>
    );
  }

  get commentTextArea() {
    return (
      <FormControl id='comment' mt={5} isInvalid={this.state.isSubmitted && !this.props.experience && isEmpty(this.state.bookingItem.comment)} isRequired={!this.props.experience}>
        <FormLabel>{this.props.t('drawers.editBookingItem.comment.label')}</FormLabel>
        <Textarea
          size="sm"
          onChange={this.onCommentChange}
          height="140px"
          fontSize={14}
          placeholder={this.props.t('drawers.editBookingItem.comment.placeholder')}
          defaultValue={this.state.bookingItem.comment}
          _placeholder={{ color: 'gray.400' }}
        />
      </FormControl>
    );
  }

  get languages() {
    if (!this.props.experience?.partner) {
      return this.props.languages.list;
    }

    return this.props.experience.languages.map(({ language }) => language);
  }

  get languageSelect() {
    if (!this.props.experience) {
      return null;
    }

    return (
      <LanguageSelect
        size="sm"
        label={this.props.t('drawers.editBookingItem.language.label')}
        languages={this.languages}
        languageId={this.state.bookingItem.languageId}
        isRequired={false}
        onChange={this.onLanguageChange}
      />
    );
  }

  get participantsRangeError() {
    if (this.props.product && !isParticipantsRangeValid(this.props.product, this.state.bookingItem.participants)) {
      return (
        <Text fontSize={14} lineHeight="26px" color="red.500" mt={5}>
          <Trans
            i18nKey='experience:form.steps.information.participants.error'
            values={{ min: 1, max: getMaxThreshold(this.props.product) }}
          />
        </Text>
      );
    }
  }

  get body() {
    if (this.props.languages.status !== FETCH_STATUS.FETCHED) {
      return <Loader />;
    }

    return (
      <DrawerBody>
        <SimpleGrid columns={2} spacing={5}>
          { this.fromDatePicker }
          { this.timeSelect }
          { this.participantsInput }
          { this.languageSelect }
        </SimpleGrid>

        { this.commentTextArea }
        { this.participantsRangeError }
      </DrawerBody>
    );
  }

  get header() {
    return (
      <DrawerHeader>
        { this.props.t(`drawers.editBookingItem.title`) }
      </DrawerHeader>
    );
  }

  get isSubmitDisabled() {
    if (this.state.bookingItem.participants <= 0) {
      return true;
    }

    if (!this.state.bookingItem.timeSlot.from) {
      return true;
    }

    if (!this.props.experience) {
      return isEmpty(this.state.bookingItem.comment);
    }

    if (this.props.product && !isParticipantsRangeValid(this.props.product, this.state.bookingItem.participants)) {
      return true;
    }

    return false;
  }

  get footer() {
    return (
      <DrawerFooter>
        <HStack justifyContent="space-between" w="100%">
          <SecondaryButton
            size="sm"
            colorScheme="pink"
            onClick={this.onClose}
          >
            {this.props.t('buttons.close', { ns: 'common' })}
          </SecondaryButton>
          <PrimaryButton
            size="sm"
            colorScheme="teal"
            onClick={this.onSubmit}
            isDisabled={this.isSubmitDisabled}
            isLoading={this.state.isSubmitted}
          >
            {this.props.t('buttons.save', { ns: 'common' })}
          </PrimaryButton>
        </HStack>
      </DrawerFooter>
    );
  }

  render() {
    return (
      <>
        { React.cloneElement(this.props.children, { onClick: this.onOpen }) }

        <Drawer isOpen={this.state.isOpen} onClose={this.onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            {this.header}
            {this.body}
            {this.footer}
          </DrawerContent>
        </Drawer>
      </>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    languages: state.languages,
    user: state.user
  };
}

const mapDispatchToProps: DispatchProps = {
  getLanguagesThunk,
  updateBookingItem,
  showToast,
  trackEvent,
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('booking')(EditBookingItemDrawer));