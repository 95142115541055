import { CurrencyExchangeRates } from '../entities/Currency';
import { UserDetails } from '../entities/User';
import { getConversionRate } from './currencies';

export const PAY_VIA_JURNEE_TAKE_RATE = 0.05;
export const PAY_VIA_JURNEE_TARGET_CURRENCY = 'EUR';

export interface PayViaJurneePricing {
  conversionRate: number;
  feeAmount: number;
  sourceCurrency: string;
  targetCurrency: string;
  totalAmount: number;
}

interface PriceInCurrencyParams {
  value: number;
  currency: string;
  targetCurrency: UserDetails['currency'];
  fractionDigits?: number;
}

const locale = typeof window !== 'undefined' && window.navigator ? window.navigator.language : 'en-US';

export function formatPrice(value: number, currency: string, options: Intl.NumberFormatOptions = {}) {
  return value.toLocaleString(locale, {
    style: 'currency',
    currency,
    ...options
  }).replace(' ', ' ');
}

export function formatPriceInUserCurrency(value: number, currency: string, user: Pick<UserDetails, 'currency'>, options: Intl.NumberFormatOptions = {}) {
  if (user.currency.id === currency) {
    return formatPrice(value, currency, options);
  }

  try {
    const exchange = user.currency.targetExchangeRates.find(({ sourceId }) => sourceId === currency);

    if (exchange === undefined) {
      throw new Error(`Missing exchange rate ${currency} => ${user.currency.id }`);
    }

    return formatPrice(value * exchange.rate, user.currency.id, options);
  } catch (err) {
    return formatPrice(value, currency, options);
  }
}

export function getCurrencyChar(currency: string) {
  const intl = new Intl.NumberFormat(locale, { style: 'currency', currency });
  const match = intl.formatToParts(1).find(({ type }) => type === 'currency');

  return match ? match.value : '€';
}

export function computePayViaJurneePricing({ amount, currency }: { amount: number, currency: string }, targetCurrency: string, exchangeRates: CurrencyExchangeRates): PayViaJurneePricing {
  const conversionRate = getConversionRate(currency, targetCurrency, exchangeRates);
  const convertedAmount = parseFloat((amount * conversionRate).toFixed(2));
  const totalAmount = parseFloat((convertedAmount / (1 - PAY_VIA_JURNEE_TAKE_RATE)).toFixed(2));
  const feeAmount = parseFloat((totalAmount - convertedAmount).toFixed(2));

  return {
    conversionRate,
    feeAmount,
    sourceCurrency: currency,
    targetCurrency,
    totalAmount
  };
}

export function formatPriceInCurrency({ value, currency, targetCurrency, fractionDigits }: PriceInCurrencyParams) {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: fractionDigits ?? 2,
    maximumFractionDigits: fractionDigits ?? 2
  };

  if (targetCurrency.id === currency) {
    return formatPrice(value, currency, options);
  }

  const exchangeRate = targetCurrency.targetExchangeRates.find(({ sourceId }) => sourceId === currency);

  if (exchangeRate?.rate) {
    return formatPrice(value * exchangeRate.rate, targetCurrency.id, options);
  }

  return formatPrice(value, currency, options);
}