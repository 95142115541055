import { HStack, IconButton, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { ExperienceDescription } from '@jurnee/common/src/components/ExperienceDetails/Description';
import { ExperienceHeader } from '@jurnee/common/src/components/ExperienceDetails/Header';
import { ExperienceInfoAndPolicies } from '@jurnee/common/src/components/ExperienceDetails/InfoAndPolicies';
import { ExperienceMap } from '@jurnee/common/src/components/ExperienceDetails/Map';
import { ExperienceReviews } from '@jurnee/common/src/components/ExperienceDetails/Reviews';
import { Icon } from '@jurnee/common/src/components/Icon';
import { Loader } from '@jurnee/common/src/components/Loader';
import { Experience, ExperienceJSON, ExperienceRatingJSON } from '@jurnee/common/src/entities/Experience';
import { getExperienceMaxParticipants, hasMap, isCompanyRecommended } from '@jurnee/common/src/utils/experiences';
import { getReviewAuthor } from '@jurnee/common/src/utils/reviews';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SecondaryButton } from 'src/components/buttons';
import AddCreateCollectionModal from 'src/modals/AddCreateCollectionModal';
import { getExperience } from 'src/store/experienceDetails/experienceDetails.thunks';
import { getExperienceStats } from 'src/store/experienceDetailsStats/experienceDetailsStats.thunks';
import { experiencesRatingsSelectors } from 'src/store/experiencesRatings/experiencesRatings.selectors';
import { getLanguagesThunk } from 'src/store/languages/languages.thunks';
import { getLocations } from 'src/store/locations/locations.thunks';
import { RootState } from 'src/store/state';
import { addToFavoriteExperiences, getUserFavoriteExperiences, removeFromFavoriteExperiences } from '../../store/userFavoriteExperiences/userFavoriteExperiences.thunks';
import { RouteProps } from '../Route';
import ExperienceDetailsForm from './Form';
import { GenericExperienceCard } from './Form/GenericExperienceCard';

interface StateProps {
  company: RootState['company'];
  experience: RootState['experienceDetails'];
  isFavorite: boolean;
  isCompanyRecommended: boolean;
  languages: RootState['languages'];
  locations: RootState['locations'];
  rating: ExperienceRatingJSON;
  stats: RootState['experienceDetailsStats'];
  userFavoriteExperiences: RootState['userFavoriteExperiences'];
}

interface DispatchProps {
  addToFavoriteExperiences(experience: ExperienceJSON): void;
  getExperience(id: Experience['id']): void;
  getExperienceStats(id: Experience['id']): void;
  getLanguagesThunk(): void;
  getLocations(): void;
  getUserFavoriteExperiences(): void;
  removeFromFavoriteExperiences(id: ExperienceJSON['id']): void;
}

type Props = StateProps & DispatchProps & RouteProps & WithTranslation;

class ExperiencesDetails extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    const { id } = props.match.params;

    if (this.props.userFavoriteExperiences.status === FETCH_STATUS.INITIAL) {
      this.props.getUserFavoriteExperiences();
    }

    this.props.getExperience(Number(id));
    this.props.getLanguagesThunk();
    this.props.getLocations();
    this.props.getExperienceStats(Number(id));
  }

  get experience() {
    return this.props.experience.data;
  }

  parseFilter(number: string) {
    return Number(number) || undefined;
  }

  onFavoriteClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (this.props.isFavorite) {
      this.props.removeFromFavoriteExperiences(this.experience.id);
    } else {
      this.props.addToFavoriteExperiences(this.experience);
    }
  };

  get partnersProvidersInformation() {
    if (!this.experience.partner) {
      return null;
    }

    return this.experience.partner.partnersProvidersInformation;
  }

  get hasExperienceMap() {
    return hasMap(this.experience);
  }

  get hasExperienceReviews() {
    if (this.props.stats.status !== 'FETCHED') {
      return false;
    }

    if (this.props.stats.data.totalReviews === 0) {
      return false;
    }

    return !!this.experience.partner;
  }

  get images() {
    return this.experience.experiencesImages.map(({ image }) => image);
  }

  get reviews() {
    return this.props.stats.data.reviews.map(review => ({
      author: getReviewAuthor(review?.review),
      rating: review.rating,
      comment: review.comment,
      createdAt: review.createdAt,
      provider: review?.review?.provider
    }));
  }

  get maxParticipants() {
    return getExperienceMaxParticipants(this.experience);
  }

  get partnerImageUrl() {
    return this.experience.experiencesImages.length > 0 ? this.experience.experiencesImages[0].image.path : null;
  }

  render() {
    if (this.props.experience.status !== FETCH_STATUS.FETCHED) {
      return <Loader/>;
    }

    return (
      <main>
        <VStack maxW="1184px" mx="auto" position="relative" p={8} spacing={8} alignItems="flex-start">
          <ExperienceHeader
            title={this.experience.name}
            images={this.images}
            isCompanyRecommended={this.props.isCompanyRecommended}
          >
            <AddCreateCollectionModal experienceId={this.experience.id}>
              <SecondaryButton size="sm" colorScheme="gray">{this.props.t('buttons.addToCollection')}</SecondaryButton>
            </AddCreateCollectionModal>

            <IconButton
              size="sm"
              aria-label="Add to Favorite"
              borderRadius={4}
              onClick={this.onFavoriteClick}
              _hover={{ background: 'gray.200' }}
              bg="gray.100"
              icon={<Icon icon={this.props.isFavorite ? 'heartFill' : 'heartLine'} size={4} color={this.props.isFavorite ? 'pink.500' : 'black'}/>}
            />
          </ExperienceHeader>

          <HStack spacing={16} w="100%" alignItems="stretch">
            <ExperienceDescription
              description={{ content: this.experience.description }}
              maxDuration={this.experience.maxDuration}
              maxParticipants={this.maxParticipants}
              type={this.experience.type}
              highlights={this.experience.highlights}
              included={this.experience.included}
              requirements={this.experience.requirementsList}
              partnerImageUrl={this.partnerImageUrl}
              partnerName={this.experience.partner?.name}
              partnerDescription={this.experience.partner?.description}
              partnersProvidersInformation={this.experience.partner?.partnersProvidersInformation ?? []}
            />

            <VStack w="100%" maxW={500} spacing={5}>
              {!this.experience.partner && <GenericExperienceCard />}

              <ExperienceDetailsForm
                experience={this.experience}
                languages={this.props.languages.list}
              />
            </VStack>
          </HStack>

          {
            this.hasExperienceMap &&
              <ExperienceMap address={this.experience.partner.address} />
          }

          {
            this.hasExperienceReviews &&
              <ExperienceReviews
                rating={{ average: this.props.rating.average, count: this.props.stats.data.totalReviews }}
                reviews={this.reviews}
                reviewsCountGroupByRating={this.props.stats.data.reviewsCountGroupByRating}
                partnersProvidersInformation={this.partnersProvidersInformation}
              />
          }

          <ExperienceInfoAndPolicies
            importantInformation={this.experience.importantInformation}
            wheelchairAccessible={this.experience.wheelchairAccessible}
            pregnantAccessible={this.experience.pregnantAccessible}
          />
        </VStack>
      </main>
    );
  }
}

function mapStateToProps(state: RootState, props: RouteProps): StateProps {
  const experienceId = Number(props.match.params.id);

  return {
    company: state.company,
    experience: state.experienceDetails,
    languages: state.languages,
    locations: state.locations,
    userFavoriteExperiences: state.userFavoriteExperiences,
    isFavorite: state.userFavoriteExperiences.list.some(({ id }) => id === experienceId),
    isCompanyRecommended: state.experienceDetails.data && isCompanyRecommended(state.experienceDetails.data, Object.values(state.companyRecommendedPartners.entities)),
    rating: experiencesRatingsSelectors.selectById(state, experienceId),
    stats: state.experienceDetailsStats
  };
}

const mapDispatchToProps: DispatchProps = {
  getExperience,
  getExperienceStats,
  getLanguagesThunk,
  getLocations,
  getUserFavoriteExperiences,
  addToFavoriteExperiences,
  removeFromFavoriteExperiences
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('experience')(ExperiencesDetails));