import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerProviderInformationJSON } from '../../../../entities/PartnerProviderInformation';
import { sanitize } from '../../../../utils/strings';
import { PartnerPhoto } from './PartnerPhoto';
import { Subtitle } from './Subtitle';

interface Props {
  imageUrl: string;
  name: string;
  description: string;
  partnersProvidersInformation: Pick<PartnerProviderInformationJSON, 'ratingAverage' | 'ratingCount'>[];
}

export function PartnerInfo(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'details.partner' });

  const { ratingAverage, ratingCount } = useMemo(
    () => props.partnersProvidersInformation.reduce((totals, { ratingAverage, ratingCount }) => {
      const ratingCountTotal = totals.ratingCount + ratingCount;
      const ratingAverageTotal = ((totals.ratingAverage * totals.ratingCount) + (ratingAverage * ratingCount)) / ratingCountTotal;

      return { ratingAverage: ratingAverageTotal, ratingCount: ratingCountTotal };
    }, { ratingAverage: 0, ratingCount: 0 }),
    [props.partnersProvidersInformation]
  );

  return (
    <VStack w="100%" spacing={5} alignItems="flex-start">
      <HStack spacing={3} alignItems="flex-start">
        <PartnerPhoto imageUrl={props.imageUrl} />

        <VStack spacing={1} alignItems="flex-start">
          <Heading size="md" noOfLines={1} mt={1}>
            { t('hostedBy') } { props.name }
          </Heading>

          { ratingCount && <Subtitle ratingAverage={ratingAverage} ratingCount={ratingCount} /> }
        </VStack>
      </HStack>

      { props.description && <Text boxSizing="border-box" dangerouslySetInnerHTML={{ __html: sanitize(props.description) }} /> }
    </VStack>
  );
}