import { Heading, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ExperienceCategory } from 'src/utils/experiences';
import { Step } from './Step';

interface Props {
  type: ExperienceCategory['type'];
  children?: ReactNode;
}

function getKeyPrefix({ type }: Props): ExperienceCategory['type'] | 'PARTNER_PLACE' {

  if (['PAY_VIA_JURNEE', 'SPECIFIC_PARTNER'].includes(type)) {
    return type;
  }

  return 'CUSTOM_REQUEST';
}

export function HowtoCard(props: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'modals.howtoCard' });

  const keyPrefix = getKeyPrefix(props);
  const imagePath = '/assets/images/event_placeholder.jpeg';

  return (
    <VStack
      minW={490}
      maxW={490}
      px={8}
      py={16}
      spacing={7}
      bgImage={`linear-gradient(rgba(0, 0, 35, 0.85), rgba(0, 0, 35, 0.85)), url(${imagePath});`}
      bgColor="rgb(0, 0, 35)"
      bgSize="cover"
      bgPosition="center"
      borderRight="1px solid var(--chakra-colors-gray-200)"
      borderRadius={4}
      color="white"
      alignSelf="stretch"
      justifyContent="center"
      alignItems="flex-start"
    >
      <VStack w="100%" spacing={1} alignItems="flex-start">
        <Heading>{t(`${keyPrefix}.title`)}</Heading>
        <Text>{t(`${keyPrefix}.description`)}</Text>
      </VStack>

      <VStack spacing={4} alignItems="flex-start">
        { [1,2,3].map(n => <Step key={n} n={n} label={t(`${keyPrefix}.step${n}`)} />) }
      </VStack>

      { props.children }
    </VStack>
  );
}