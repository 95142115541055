import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

export function ExperiencesGrid({ children, ...props }: Props & SimpleGridProps) {

  return (
    <SimpleGrid w="100%" spacing={5} columns={{ sm: 2, md: 3, lg: 4, xl: 5, '2xl': 6, '3xl': 8 }} {...props}>
      { children }
    </SimpleGrid>
  );

}