import { PlaceJSON } from '@jurnee/common/src/entities/Place';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchNearby, textSearch } from '../../api/places';
import { RootState } from '../state';

export interface PlacesSearchPayload {
  params: URLSearchParams;
  onError(error: unknown): void;
}

export const textSearchThunk = createAsyncThunk<{ list: PlaceJSON[], total: number }, PlacesSearchPayload, { state: RootState }>('PLACES_TEXT_SEARCH', async ({ params, onError }, thunkAPI) => {
  try {
    const places = await textSearch(params);
    return places;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const searchNearbyThunk = createAsyncThunk<{ list: PlaceJSON[], total: number }, PlacesSearchPayload, { state: RootState }>('PLACES_SEARCH_NEARBY', async ({ params, onError }, thunkAPI) => {
  try {
    const places = await searchNearby(params);
    return places;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});