import { FormControl, Select } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BookingJSON } from '../../../../entities/Booking';
import { sortAlphabeticallyBy } from '../../../../utils/arrays';

interface Props {
  placeholder: string;
  bookings: BookingJSON[];
  selected: number;
  onChange(bookingId: number): void;
}

export function BookingSelect({ placeholder, bookings, selected, onChange }: Props) {
  const sortedBookings = useMemo(() => sortAlphabeticallyBy(bookings, 'name'), [bookings]);

  return (
    <FormControl id="bookingId" isRequired>
      <Select size="sm" name="bookingId" bg="white" onChange={e => onChange(Number(e.target.value))} value={selected || ''}>
        <option value={0}>{placeholder}</option>
        { sortedBookings.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
      </Select>
    </FormControl>
  );
}