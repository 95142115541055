import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { searchNearbyThunk, textSearchThunk } from './places.thunks';

const slice = createSlice({
  name: 'places',
  initialState: initialState.places,
  reducers: {
    resetPlaces: () => initialState.places
  },
  extraReducers: (builder) => {

    builder.addCase(textSearchThunk.pending, (state) => {
      return { ...state, list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(textSearchThunk.fulfilled, (state, { payload: { list }}) => {
      return { ...state, list, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(textSearchThunk.rejected, (state) => {
      return { ...state, list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(searchNearbyThunk.pending, (state) => {
      return { ...state, list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(searchNearbyThunk.fulfilled, (state, { payload: { list }}) => {
      return { ...state, list, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(searchNearbyThunk.rejected, (state) => {
      return { ...state, list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export const { resetPlaces } = slice.actions;

export default slice.reducer;