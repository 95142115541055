import { VStack } from '@chakra-ui/react';
import { ExperienceRatingJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import { LinkQuery } from 'src/components/LinkQuery';
import { Image } from './Image';
import { Price } from './Price';
import { Subtitle } from './Subtitle';
import { Title } from './Title';

interface Props {
    src: string;
    to: LinkProps['to'];
    title: string;
    rating: Pick<ExperienceRatingJSON, 'average' | 'count'>;
    address: string;
    products?: ProductJSON[];
    isGeneric?: boolean;
    isRecommended?: boolean;
    children?: ReactNode;
    onMouseEnter?(): void;
    onMouseLeave?(): void;
}

export function ExperienceCard(props: Props) {
  return (
    <LinkQuery to={props.to} target="_blank" role="group">
      <VStack w="100%" spacing={3} alignItems="flex-start" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <Image src={props.src}>
          { props.children }
        </Image>

        <VStack spacing={2} alignItems="flex-start">
          <Title value={props.title} isGeneric={props.isGeneric} isRecommended={props.isRecommended} />
          <Subtitle rating={props.rating} address={props.address} isGeneric={props.isGeneric} />
        </VStack>

        <Price products={props.products} />
      </VStack>
    </LinkQuery>
  );
}