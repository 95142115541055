import {
  Box,
  Heading,
  HStack, SimpleGrid,
  Spacer
} from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { getCollections } from '@jurnee/dashboard/src/store/collections/collections.thunks';
import * as React from 'react';
import { connect } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import CollectionCard from 'src/components/CollectionCard';
import EmptyState from 'src/components/EmptyState';
import AddCreateCollectionModal from 'src/modals/AddCreateCollectionModal';
import { RootState } from 'src/store/state';
import { RouteProps } from '../Route';

interface StateProps {
  collections: RootState['collections'];
}

interface DispatchProps {
  getCollections(): void;
}

class Collections extends React.Component<StateProps & DispatchProps> {

  componentDidMount() {
    this.props.getCollections();
  }

  get collectionsCards() {
    return this.props.collections.list.map(collection => (
      <CollectionCard collection={collection} key={collection.id} />
    ));
  }

  get collections() {
    if (this.props.collections.status !== FETCH_STATUS.FETCHED) {
      return <Box h={500}><Loader/></Box>;
    }

    if (this.props.collections.list.length === 0) {
      return <EmptyState
        imagePath="/assets/illustrations/folders.svg"
        heading="You currently have no lists"
        description="Lists allow you to group experiences together and send them to your team to vote on"
      />;
    }

    return (
      <SimpleGrid spacing={8} columns={{ md: 2, lg: 3, xl: 4, '2xl': 5, '3xl': 7 }}>
        {this.collectionsCards}
      </SimpleGrid>
    );
  }

  render() {
    return (
      <main>
        <HStack justifyContent="space-between" bg="white" p={8} borderBottom="1px solid" borderColor="blue.50">
          <Heading w="100%">Lists</Heading>
          <Spacer />
          <Box>
            <AddCreateCollectionModal>
              <PrimaryButton size="sm">New list</PrimaryButton>
            </AddCreateCollectionModal>
          </Box>
        </HStack>
        <Box p={8}>
          {this.collections}
        </Box>
      </main>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    collections: state.collections
  };
}

const mapDispatchToProps: DispatchProps = {
  getCollections,
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Collections);