import { Box, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { NumberInput } from '@jurnee/common/src/components/NumberInput';
import { CollectionUpdateBody } from '@jurnee/common/src/dtos/collections';
import { Collection } from '@jurnee/common/src/entities/Collection';
import { isDateBefore } from '@jurnee/common/src/utils/dates';
import { getCurrencyChar } from '@jurnee/common/src/utils/prices';
import * as React from 'react';
import { PrimaryButton } from '../components/buttons';

interface OwnProps {
  collection: Collection;
  currency: string;
  isLoading: boolean;
  onSave(data: Omit<CollectionUpdateBody, 'title'>): void;
}

type State = Omit<CollectionUpdateBody, 'title'>;

export default class CollectionDetailsForm extends React.PureComponent<OwnProps, State> {

  state: State = {
    estimatedParticipants: this.props.collection.estimatedParticipants,
    maxBudget: this.props.collection.maxBudget,
    pollDeadline: this.props.collection.pollDeadline ? new Date(this.props.collection.pollDeadline) : null,
  };

  handleSave = () => {
    this.props.onSave({
      estimatedParticipants: this.state.estimatedParticipants,
      maxBudget: this.state.maxBudget,
      pollDeadline: this.state.pollDeadline,
    });
  };

  handleEstimatedParticipants = (value: string) => {
    this.setState({
      ...this.state,
      estimatedParticipants: Number(value)
    });
  };

  handleMaxBudget = (value: string) => {
    this.setState({
      ...this.state,
      maxBudget: Number(value)
    });
  };

  handlePollDeadline = (pollDeadline: Date) => {
    this.setState({
      ...this.state,
      pollDeadline
    });
  };

  get estimatedParticipantsFormControl() {
    return (
      <FormControl>
        <FormLabel>Estimated participants</FormLabel>
        <NumberInput
          size="sm"
          defaultValue={this.state.estimatedParticipants}
          min={0}
          name="estimatedParticipants"
          onChange={this.handleEstimatedParticipants}
          step={1}
        />
      </FormControl>
    );
  }

  get maxBudgetFormControl() {
    return (
      <FormControl>
        <FormLabel>Max. budget {getCurrencyChar(this.props.currency)}</FormLabel>
        <NumberInput
          size="sm"
          defaultValue={this.state.maxBudget}
          min={0}
          name="maxBudget"
          onChange={this.handleMaxBudget}
          step={10}
        />
      </FormControl>
    );
  }

  filterDate = (date: Date) => {
    return !isDateBefore(date, new Date());
  };

  get pollDeadlineFormControl() {
    return (
      <FormControl>
        <FormLabel>Poll closing date</FormLabel>
        <DatePicker
          popperPlacement="top"
          dateFormat="dd MMM yyyy"
          filterDate={this.filterDate}
          onChange={this.handlePollDeadline}
          selected={this.state.pollDeadline}
          placeholderText="Select a date"
          inputProps={{ size: 'sm' }}
        />
      </FormControl>
    );
  }

  get saveButton() {
    return (
      <Box pt={4} w="100%" textAlign="right">
        <PrimaryButton
          colorScheme="teal"
          isLoading={this.props.isLoading}
          onClick={this.handleSave}
          size="sm"
        >
          Save
        </PrimaryButton>
      </Box>
    );
  }

  render() {
    return (
      <VStack minW="260px" bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={4} spacing={4}>
        {this.estimatedParticipantsFormControl}
        {this.maxBudgetFormControl}
        {this.pollDeadlineFormControl}
        {this.saveButton}
      </VStack>
    );
  }

}