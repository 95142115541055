import { HStack, IconButton, useToast } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FavoriteButton from 'src/components/buttons/FavoriteButton';
import AddCreateCollectionModal from 'src/modals/AddCreateCollectionModal';
import { useAppDispatch } from 'src/store';
import { addToFavoriteExperiences, removeFromFavoriteExperiences } from 'src/store/userFavoriteExperiences/userFavoriteExperiences.thunks';
import { getUserFavoriteExperiencesSelector } from 'src/store/userFavoriteExperiences/userFavortieExperiences.selectors';

interface Props {
  experience: ExperienceJSON;
}

export function Buttons(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('experience', { keyPrefix: 'toasts' });

  const userFavoriteExperiences = useSelector(getUserFavoriteExperiencesSelector);

  const isFavorite = useMemo(() => userFavoriteExperiences.some(({ id }) => id === props.experience.id), [props.experience.id, userFavoriteExperiences]);

  async function onFavoriteClick(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (isFavorite) {
      try {
        await dispatch(removeFromFavoriteExperiences(props.experience.id)).unwrap();
      } catch(e) {
        toast(getErrorToast(t('removeFromFavorite.error')));
      }
    } else {
      try {
        await dispatch(addToFavoriteExperiences(props.experience)).unwrap();
      } catch(e) {
        toast(getErrorToast(t('addToFavorite.error')));
      }
    }
  };

  return (
    <HStack w="100%" spacing={4} alignItems="flex-start">
      <FavoriteButton
        onClick={onFavoriteClick}
        aria-label="Favorite Experience"
        isActive={isFavorite}
        _groupHover={{ opacity: 1 }}
        opacity={isFavorite ? 1 : 0}
      />

      <AddCreateCollectionModal experienceId={props.experience.id}>
        <IconButton
          aria-label="Add to list"
          borderRadius={4}
          bg="white"
          _groupHover={{ opacity: 1 }}
          opacity={0}
          _hover={{ background: 'whiteAlpha.800' }}
          size="sm"
          icon={<Icon icon="folderIn" size={5} color="black" />}
        />
      </AddCreateCollectionModal>
    </HStack>
  );
}