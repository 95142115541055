import { api } from '@jurnee/common/src/browser/Api';
import { PlaceJSON } from '@jurnee/common/src/entities/Place';
import { List } from '@jurnee/common/src/serializers';

export function textSearch(params: URLSearchParams): Promise<List<PlaceJSON, never>> {
  return api.get(`places/search/text?${params.toString()}`);
}

export function searchNearby(params: URLSearchParams): Promise<List<PlaceJSON, never>> {
  return api.get(`places/search/nearby?${params.toString()}`);
}