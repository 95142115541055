import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const md = defineStyle({
  borderRadius: '8px',
  padding: '10px 16px'
});

export const Tooltip = defineStyleConfig({
  baseStyle: {
    borderRadius: 4
  },
  sizes: { md }
});