import { Badge, Box, Flex, HStack, Link, Spacer, StackProps, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ItemAddress } from '@jurnee/common/src/components/ItemAddress';
import { PhotosDrawer } from '@jurnee/common/src/drawers/PhotosDrawer';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { PropositionItemJSON } from '@jurnee/common/src/entities/PropositionItem';
import { PLACEHOLDER_PATH } from '@jurnee/common/src/utils/bookings';
import { getBookingItemTotalPrice, getItemTimezone, getPlaceholderImagePath } from '@jurnee/common/src/utils/bookingsItems';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { getExperienceImage, getExperiencePath } from '@jurnee/common/src/utils/experiences';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { PriceInUserCurrency } from './PriceInUserCurrency';

interface Props extends WithTranslation {
  item: BookingItemJSON | PropositionItemJSON;
  experience: ExperienceJSON;
  currency: Currency;
  children: React.ReactNode;
  stackProps?: StackProps;
  isProcessing?: boolean;
}

class ItemCard extends React.Component<Props> {

  get participants(): string {
    return this.props.t('booking:participants', { count: this.props.item.participants });
  }

  get date() {
    const date = new Date(this.props.item.from);

    return formatDate(date, {
      displayTime: true,
      displayTimeZone: true,
      timeZone: getItemTimezone(this.props.item)
    });
  }

  isBookingItem(item: BookingItemJSON | PropositionItemJSON): item is BookingItemJSON {
    return (item as BookingItemJSON).bookingId !== undefined;
  }

  isPropositionItem(item: PropositionItemJSON | BookingItemJSON): item is PropositionItemJSON {
    return (item as PropositionItemJSON).propositionId !== undefined;
  }

  get imagePath() {
    if (this.isBookingItem(this.props.item) && this.props.item.bookingsItemsImages.length > 0) {
      return this.props.item.bookingsItemsImages[0].image.path;
    }

    if (this.isPropositionItem(this.props.item) && this.props.item.propositionsItemsImages.length > 0) {
      return this.props.item.propositionsItemsImages[0].image.path;
    }

    if (this.props.experience) {
      return getExperienceImage(this.props.experience).path;
    }

    return getPlaceholderImagePath(this.props.item.bookingItemType.key);
  }

  get itemImages() {
    if (this.isBookingItem(this.props.item)) {
      return this.props.item.bookingsItemsImages.map(({ image }) => image);
    }

    return this.props.item.propositionsItemsImages.map(({ image }) => image);
  }

  get images() {
    if (this.isPropositionItem(this.props.item) && this.props.experience) {
      return this.itemImages.concat(this.props.experience.experiencesImages.map(({ image }) => image));
    }

    return this.itemImages;
  }

  get image() {
    const photosDrawerEnabled = this.images.length > 1;
    const hoverProps = photosDrawerEnabled ? { _hover: { cursor: 'pointer', 'svg': { color: 'gray.100' } } } : {};

    const image = (
      <Box
        minW={108}
        minH={108}
        bgImage={`linear-gradient(rgba(30, 0, 45, 0.30), rgba(30, 0, 45, 0.30)), url('${getCdnImageUrl(this.imagePath)}'), url('${getCdnImageUrl(PLACEHOLDER_PATH)}');`}
        bgColor="rgb(30, 0, 45)"
        bgPosition="center"
        bgSize="cover"
        alignSelf="stretch"
        borderRadius="4px"
        position="relative"
        {...hoverProps}
      >
        {
          photosDrawerEnabled &&
            <Icon
              icon="copy"
              color="white"
              size={5}
              position="absolute"
              top={2}
              right={2}
              cursor="pointer"
              filter="drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4))"
            />
        }
      </Box>
    );

    if (photosDrawerEnabled) {
      return (
        <PhotosDrawer images={this.images}>
          { image }
        </PhotosDrawer>
      );
    }

    return image;
  }

  get price() {
    const price = getBookingItemTotalPrice(this.props.item);

    return <PriceInUserCurrency value={price} currency={this.props.currency}/>;
  }

  get comment() {
    return (
      <Text lineHeight="16px" noOfLines={2} color="gray.400">
        { this.props.item.comment }
      </Text>
    );
  }

  get title() {
    if (!this.props.experience) {
      return (
        <Text lineHeight="16px" noOfLines={1} fontWeight={700}>
          { this.props.item.description }
        </Text>
      );
    }

    return (
      <Link to={getExperiencePath(this.props.experience)} as={ReactRouterLink} lineHeight="16px" noOfLines={1} fontWeight={700}>
        { this.props.item.description }
      </Link>
    );
  }

  render() {
    return (
      <Flex w="100%" gap={5} {...this.props.stackProps}>
        { this.image }

        <Flex w="100%" flexDirection="column" py={1}>
          <HStack w="100%" justifyContent="space-between">
            { this.title }
            <Text lineHeight="16px" noOfLines={1} fontWeight={700}>{this.price}</Text>
          </HStack>

          <Flex w="100%" h="100%" mt="6px" flexDirection="column" gap="5px">
            <HStack w="100%" justifyContent="space-between">
              <Text lineHeight="16px" noOfLines={1}>{this.date}</Text>

              {
                this.props.isProcessing &&
                  <Badge variant='pink'>
                    { this.props.t('bookingItems.status.PROCESSING') }
                  </Badge>
              }
            </HStack>

            <ItemAddress item={this.props.item} lineHeight="16px" noOfLines={1} color="gray.400" />

            <Text lineHeight="16px" noOfLines={1} color="gray.400">{this.participants}</Text>
          </Flex>

          <Spacer />

          { this.props.children }
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('booking')(ItemCard);