import { Divider, HStack } from '@chakra-ui/react';
import { CancellationPolicy } from './CancellationPolicy';
import { ImportantInfo } from './ImportantInfo';

interface Props {
  importantInformation: string[];
  wheelchairAccessible: boolean;
  pregnantAccessible: boolean;
}

export function ExperienceInfoAndPolicies(props: Props) {
  return (
    <>
      <Divider />

      <HStack w="100%" alignItems="flex-start">
        <ImportantInfo
          importantInformation={props.importantInformation}
          wheelchairAccessible={props.wheelchairAccessible}
          pregnantAccessible={props.pregnantAccessible}
        />
        <CancellationPolicy />
      </HStack>
    </>
  );
}