import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props {
  src: string;
  children?: ReactNode;
}

export function Image({ src, children }: Props) {
  return (
    <Box
      w="100%"
      style={{ aspectRatio: '1' }}
      p={4}
      bg={`linear-gradient(rgba(30, 0, 50, 0.1), rgba(30, 0, 50, 0.1)), url('${src}') no-repeat center / cover`}
      borderRadius={8}
    >
      { children }
    </Box>
  );
}