import { Center, HStack, StackProps } from '@chakra-ui/react';
import { Icon, IconKey } from '@jurnee/common/src/components/Icon';

export type ViewKey = Extract<IconKey, 'list' | 'calendar' | 'grid' | 'map'>;

interface Props {
  views: ViewKey[];
  currentView: ViewKey;
  onViewChange(view: ViewKey): void;
}

export default function ViewSelector(props: Props & StackProps) {
  const { views, currentView, onViewChange, ...inheritedProps } = props;

  function selectors() {
    return views.map(view => {
      const isCurrentView = currentView === view;
      const color = isCurrentView ? 'black' : 'gray.400';
      const bgColor = isCurrentView ? 'white' : 'gray.10';

      return (
        <Center
          key={view}
          w="38px"
          h="100%"
          bgColor={bgColor}
          cursor="pointer"
          borderRight="1px solid"
          borderColor="blue.50"
          _last={{ borderRight: 0 }}
          onClick={() => props.onViewChange(view)}
        >
          <Icon icon={view} size={4} color={color} />
        </Center>
      );
    });
  }

  return (
    <HStack
      border="1px solid"
      borderColor="blue.50"
      borderRadius={4}
      spacing={0}
      overflow="hidden"
      height="32px"
      {...inheritedProps}
    >
      { selectors() }
    </HStack>
  );

}