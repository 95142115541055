import { Heading, HStack, Spacer, VStack } from '@chakra-ui/react';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import ViewSelector from '@jurnee/common/src/components/ViewSelector';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SearchBar, SearchBarParams } from 'src/components/SearchBar';
import { hasViewSelector } from 'src/utils/experiences';
import { ExperiencesStateFilters, ExperienceView } from '..';
import { ExperiencesTabs } from './ExperiencesTabs';

interface Props {
  filters: Pick<ExperiencesStateFilters, 'type' | 'query'>;
  booking: BookingJSON;
  defaultCityValue: string;
  hasCitySearch: boolean;
  currentView: ExperienceView;
  onSearchSubmitted(data: SearchBarParams): void;
  onViewChange(view: ExperienceView): void;
}

export function ExperiencesHeader(props: Props) {
  const { t } = useTranslation('experiences');

  const searchBarPlaceholder = useMemo(() => {
    switch (props.filters.type) {
    case 'RESTAURANT':
      return t('searchbar.placeholder.restaurant');
    case 'BAR':
      return t('searchbar.placeholder.bar');
    case 'BOX_SHIPPING':
      return t('searchbar.placeholder.box');
    default:
      return t('searchbar.placeholder.activity');
    }
  }, [props.filters.type]);

  return (
    <VStack w="100%" spacing={0}>
      <HStack w="100%" bg="white" spacing={4} px={8} py={7}>
        <Link to={props.booking ? `/bookings/${props.booking.id}` : '/experiences'}>
          <BackButton />
        </Link>

        <Heading>{t('header.title')}</Heading>

        <Spacer />

        <HStack spacing={5}>
          <SearchBar
            key={props.filters.query}
            onSubmit={props.onSearchSubmitted}
            query={props.filters.query}
            placeholder={searchBarPlaceholder}
            hasCitySearch={props.hasCitySearch}
            defaultCityValue={props.defaultCityValue}
          />

          {
            hasViewSelector(props.filters.type) &&
              <ViewSelector
                views={['grid', 'map']}
                currentView={props.currentView}
                onViewChange={props.onViewChange}
              />
          }
        </HStack>
      </HStack>

      <ExperiencesTabs query={props.filters.query} booking={props.booking} />
    </VStack>
  );
}