import { RootState } from '../state';
import { experiencesRatingsAdapter } from './experiencesRatings.adapter';

export const experiencesRatingsSelectors = experiencesRatingsAdapter.getSelectors(
  (state: RootState) => state.experiencesRatings
);

export function getExperienceRatingById(experienceId: number) {
  return (state: RootState) => experiencesRatingsSelectors.selectById(state, experienceId);
}

export function getExperiencesRatingsSelector(state: RootState) {
  return experiencesRatingsSelectors.selectAll(state);
}