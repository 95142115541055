import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { YearlyBudgetJSON } from '@jurnee/common/src/entities/YearlyBudget';
import { getBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BudgetChart from 'src/components/Budget/BudgetChart';
import { PriceInUserCurrency } from 'src/components/PriceInUserCurrency';
import { getCompanySelector } from 'src/store/company/company.selector';

interface Props {
  yearlyBudget: YearlyBudgetJSON;
}

interface CommitmentCardProps {
  budgetCommitment: CompanyJSON['budgetCommitment'];
}

function CommitmentCard({ budgetCommitment }: CommitmentCardProps) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.overview' });

  if (budgetCommitment) {
    return (
      <Box bgImage="/assets/images/bg_shapes_dark.jpeg" bgSize="cover" bgPos="center" borderRadius="4px 4px 0 0" p={5} h={145}>
        <HStack>
          <HStack w={8} h={8} bg="whiteAlpha.300" borderRadius={8} justifyContent="center" alignItems="center">
            <Icon icon="file" color="white" size={5} />
          </HStack>
        </HStack>

        <Text color="white" fontSize={32} fontWeight={700} lineHeight={8} mt={5}>
          <PriceInUserCurrency value={budgetCommitment.amount} currency={budgetCommitment.currency} fractionDigits={0}/>
        </Text>

        <Text color="gray.400" fontSize={14}>
          { t('expensesCommitted') }
        </Text>
      </Box>
    );
  }

  return (
    <VStack alignItems="center" bgImage="/assets/images/bg_shapes_dark.jpeg" bgSize="cover" bgPos="center" borderRadius="4px 4px 0 0" p={5} h={145}>
      <Text color="white" fontSize={14} textAlign="center" letterSpacing="-0.75px">
        <Trans
          i18nKey="settings:budgets.overview.noCommitment"
          components={{
            intercom: <Link id="intercom-launcher" textDecoration="underline" />,
            mailto: <Link href="mailto:hello@jurnee.io" textDecoration="underline"/>
          }}
        />
      </Text>
    </VStack>
  );
}

export function BudgetsOverview({ yearlyBudget }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.overview' });

  const company = useSelector(getCompanySelector);

  return (
    <Box>
      <CommitmentCard budgetCommitment={company.budgetCommitment} />

      <Box bg="white" borderRadius="0 0 4px 4px" border="1px solid" borderColor="blue.50" p={5}>
        <HStack spacing={2}>
          <HStack w={8} h={8} bg="gray.100" borderRadius={8} justifyContent="center" alignItems="center">
            <Icon icon="calendarStats" color="black" size={5} />
          </HStack>

          <Text fontSize={12} fontWeight={700} textTransform="uppercase">
            { t('plannedAnnualBudget') }
          </Text>
        </HStack>

        <BudgetChart
          mt={5}
          spent={yearlyBudget.spent}
          pending={yearlyBudget.pending}
          remaining={getBudgetRemaining(yearlyBudget)}
          total={yearlyBudget.total}
          currency='EUR'
        />
      </Box>
    </Box>
  );
}