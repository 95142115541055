import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  isGeneric?: boolean;
  isRecommended: boolean;
}

function ExperienceIcon({ isGeneric, isRecommended }: Pick<Props, 'isGeneric' | 'isRecommended'>) {
  const { t } = useTranslation('experience', { keyPrefix: 'specs' });

  if (!isGeneric && !isRecommended) {
    return null;
  }

  if (isRecommended) {
    return (
      <Tooltip label={t('recommendedByCompany')} hasArrow>
        <Box h="16px">
          <Icon icon="rosetteCheck" color="teal.400" size={4} verticalAlign="inital" />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={t('customRequestInspiration')} hasArrow>
      <Box h="16px">
        <Icon icon="bulb" color="yellow.500" size={4} verticalAlign="inital" />
      </Box>
    </Tooltip>
  );
}

export function Title({ value, isGeneric, isRecommended }: Props) {
  return (
    <HStack spacing={1}>
      <Text fontWeight={700} lineHeight="16px" noOfLines={1}>
        { value }
      </Text>

      <ExperienceIcon isGeneric={isGeneric} isRecommended={isRecommended} />
    </HStack>
  );
}