import {
  UseToastOptions
} from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { CitySearchParams } from '@jurnee/common/src/components/AddressSearch';
import { EventStep, RadioValue } from '@jurnee/common/src/components/ExperienceDetails/Form/EventStep';
import { FormTabs } from '@jurnee/common/src/components/ExperienceDetails/Form/FormTabs';
import { DEFAULT_PARTICIPANTS, InfoStep } from '@jurnee/common/src/components/ExperienceDetails/Form/InfoStep';
import { Loader } from '@jurnee/common/src/components/Loader';
import { TimeSlot } from '@jurnee/common/src/components/TimeSlotSelector';
import { BookingItemCreateBody } from '@jurnee/common/src/dtos/bookings';
import { PlaceAddressDTO } from '@jurnee/common/src/dtos/places';
import { PropositionsGroupCreateBody } from '@jurnee/common/src/dtos/propositionsGroups';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { LanguageJSON } from '@jurnee/common/src/entities/Language';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { Expand } from '@jurnee/common/src/serializers';
import { getAddressFromPlaceAddress } from '@jurnee/common/src/utils/addresses';
import { sortBy } from '@jurnee/common/src/utils/arrays';
import { getDefaultProductId, isBoxShipping, isGeneric, isVirtual } from '@jurnee/common/src/utils/experiences';
import { isCustomRequestProduct, isParticipantsRangeValid } from '@jurnee/common/src/utils/products';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PropositionsGroupCreateParams } from 'src/api/propositionsGroups';
import router from 'src/router';
import { AppDispatch } from 'src/store';
import { trackEvent, TrackEventOptions } from 'src/store/analytics/analytics.thunks';
import { getEditableBookingsSelector } from 'src/store/bookings/bookings.selectors';
import { BookingCreatePayload, BookingsFiltersParams, createBooking, getBookings } from 'src/store/bookings/bookings.thunks';
import { BookingItemsCreatePayload, createBookingItems } from 'src/store/bookingsItems/bookingsItems.thunks';
import { getBudgetsByOrganizerIdSelector } from 'src/store/budgets/budgets.selectors';
import { getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { createPropositionsGroupThunk } from 'src/store/propositionsGroups/propositionsGroups.thunks';
import { RootState } from 'src/store/state';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { getZonedTimeSlot } from 'src/utils/date';

interface StoreProps {
  bookings: RootState['bookings'];
  budgets: BudgetJSON[];
  draftBookings: BookingJSON[];
  user: UserDetails;
}

interface DispatchProps {
  showToast(payload: UseToastOptions): void;
  createBooking(data: BookingCreatePayload): Promise<Expand<BookingJSON, BookingRelationshipsJSON>>;
  createBookingItems(data: BookingItemsCreatePayload): Promise<BookingJSON>;
  createPropositionsGroupThunk(data: PropositionsGroupCreateParams): Promise<Expand<BookingJSON, BookingRelationshipsJSON>>;
  getBookings(params: BookingsFiltersParams): void;
  getUserBudgets(): void;
  trackEvent(opts: TrackEventOptions): void;
}

interface OwnProps {
  experience: ExperienceJSON;
  languages: LanguageJSON[];
}

interface State {
  name: string;
  bookingId: number;
  budgetId: number;
  bookingItem: {
    date: Date;
    timeSlot: TimeSlot;
    address: PlaceAddressDTO;
    participants: number;
    quantity: number;
    languageId: number;
    comment: string;
    productId: number;
  };
  submitting: boolean;
  selectedRadio: RadioValue;
  areBudgetsLoading: boolean;
}

type Props = StoreProps & DispatchProps & OwnProps & WithTranslation;

class ExperienceDetailsForm extends React.Component<Props, State> {

  state: State = {
    name: this.props.experience.name,
    bookingId: this.props.draftBookings.length === 1 ? this.props.draftBookings[0].id : 0,
    budgetId: null,
    bookingItem: {
      date: null,
      address: null,
      timeSlot: { from: null, to: null },
      participants: DEFAULT_PARTICIPANTS,
      quantity: 1,
      languageId: this.defaultLanguageId,
      comment: null,
      productId: this.defaultProductId
    },
    submitting: false,
    selectedRadio: 'EXISTING_EVENT',
    areBudgetsLoading: false
  };

  async componentDidMount(): Promise<void> {
    const after = Date.now().toString();
    const searchParams = new URLSearchParams({ after });

    await this.props.getBookings({ scope: 'user', searchParams });

    this.setState({ ...this.state, areBudgetsLoading: true });
    await this.props.getUserBudgets();
    this.setState({ ...this.state, areBudgetsLoading: false });
  }

  get defaultLanguageId() {
    if (this.props.experience.languages.length === 0) {
      return null;
    }

    return this.props.experience.languages[0].language.id;
  }

  get defaultProductId() {
    return getDefaultProductId(this.props.experience, DEFAULT_PARTICIPANTS);
  }

  onValidateInfoStep = () => {
    this.props.trackEvent({
      name: 'validated_experience_information',
      properties: {
        experienceId: this.props.experience.id,
        productId: this.state.bookingItem.productId
      }
    });
  };

  get zonedTimeSlot() {
    const { date, timeSlot } = this.state.bookingItem;

    if (this.isBoxShipping) {
      return getZonedTimeSlot(date, { from: '00:00', to: '00:00' }, DEFAULT_UTC_TIMEZONE);
    }

    return getZonedTimeSlot(date, timeSlot, DEFAULT_UTC_TIMEZONE);
  }

  get bookingItemAddress() {
    if (this.props.experience.partner && !this.isVirtual) {
      return null;
    }

    if (this.isBoxShipping) {
      return null;
    }

    return getAddressFromPlaceAddress(this.state.bookingItem.address);
  }

  get bookingItem(): BookingItemCreateBody {
    const { from, to } = this.zonedTimeSlot;

    return {
      address: this.bookingItemAddress,
      comment: this.state.bookingItem.comment,
      from,
      languageId: this.state.bookingItem.languageId,
      participants: this.state.bookingItem.participants,
      productId: this.state.bookingItem.productId,
      quantity: this.state.bookingItem.quantity,
      to
    };
  }

  get propositionsGroup(): PropositionsGroupCreateBody {
    return {
      address: this.bookingItem.address,
      content: this.bookingItem.comment,
      from: this.bookingItem.from,
      participants: this.bookingItem.participants,
      languageId: this.bookingItem.languageId,
      productId: this.bookingItem.productId
    };
  }

  createBooking = async (items: { propositionsGroups: PropositionsGroupCreateBody[] } | { bookingsItems: BookingItemCreateBody[] }) => {
    try {
      const { data, relationships } = await this.props.createBooking({
        body: {
          name: this.state.name,
          budgetId: this.state.budgetId || null,
          origin: 'DASHBOARD_BOOKING_FORM',
          ...items
        }
      });

      this.props.showToast({ title: this.props.t('form.toasts.createBooking.success'), status: 'success' });

      const search = 'propositionsGroups' in items ?
        new URLSearchParams({
          tab: 'requests',
          propositionsGroupId: sortBy(relationships.propositionsGroups, 'id').at(-1).id.toString()
        }).toString() :
        null;

      router.navigate({ pathname: `/bookings/${data.id}`, search });
    } catch(err) {
      const title = err instanceof Error ? err.message : this.props.t('form.toasts.createBooking.error');
      this.props.showToast({ title, status: 'error' });
    }
  };

  createBookingItems = async () => {
    try {
      const booking = await this.props.createBookingItems({
        bookingId: this.state.bookingId,
        body: {
          bookingsItems: [this.bookingItem]
        }
      });

      this.props.showToast({ title: this.props.t('form.toasts.createItems.success'), status: 'success' });
      router.navigate(`/bookings/${booking.id}`);
    } catch(err) {
      this.props.showToast({ title: this.props.t('form.toasts.createItems.error'), status: 'error' });
    }
  };

  createPropositionsGroup = async () => {
    try {
      const { data, relationships } = await this.props.createPropositionsGroupThunk({
        bookingId: this.state.bookingId,
        data: this.propositionsGroup,
      });

      this.props.showToast({ title: this.props.t('form.toasts.createItems.success'), status: 'success' });

      const search = new URLSearchParams({
        tab: 'requests',
        propositionsGroupId: sortBy(relationships.propositionsGroups, 'id').at(-1).id.toString()
      });

      router.navigate({
        pathname: `/bookings/${data.id}`,
        search: search.toString()
      });
    } catch(err) {
      const title = err instanceof Error ? err.message : this.props.t('form.toasts.createItems.error');
      this.props.showToast({ title, status: 'error' });
    }
  };

  onValidateEventStep = async () => {
    this.setState({ ...this.state, submitting: true });

    if (this.props.draftBookings.length > 0 && this.state.selectedRadio === 'EXISTING_EVENT') {
      if (isCustomRequestProduct(this.product)) {
        await this.createPropositionsGroup();
      } else {
        await this.createBookingItems();
      }
    } else {
      if (isCustomRequestProduct(this.product)) {
        await this.createBooking({ propositionsGroups: [this.propositionsGroup] });
      } else {
        await this.createBooking({ bookingsItems: [this.bookingItem] });
      }
    }

    this.props.trackEvent({
      name: 'submitted_experience_booking',
      properties: {
        experienceId: this.props.experience.id,
        productId: this.state.bookingItem.productId
      }
    });

    this.setState({ ...this.state, submitting: false });
  };

  setParticipants = (participants: number) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, participants } });
  };

  setComment = (comment: string) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, comment } });
  };

  setName = (name: string) => {
    this.setState({ ...this.state, name });
  };

  setLanguageId = (languageId: number) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, languageId } });
  };

  setBookingId = (bookingId: number) => {
    this.setState({ ...this.state, bookingId });
  };

  setBudgetId = (budgetId: number) => {
    this.setState({ ...this.state, budgetId });
  };

  setSelectedRadio = (selectedRadio: RadioValue) => {
    this.setState({ ...this.state, selectedRadio });
  };

  setProductId = (productId: ProductJSON['id']) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, productId } });
  };

  setDates = (date: Date) => {
    if (!date) {
      return;
    }

    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, date } });
  };

  setTime = (timeSlot: TimeSlot) => {
    this.setState({ ...this.state, bookingItem: { ...this.state.bookingItem, timeSlot } });
  };

  get product() {
    return this.props.experience.products.find(({ id }) => id === this.state.bookingItem.productId);
  }

  get isBoxShipping() {
    return isBoxShipping(this.props.experience);
  }

  get isVirtual() {
    return isVirtual(this.props.experience);
  }

  get isGeneric() {
    return isGeneric(this.props.experience);
  }

  get isEventStepSubmitDisabled() {
    if (this.props.draftBookings.length > 0 && this.state.selectedRadio === 'EXISTING_EVENT' && this.state.bookingId === 0) {
      return true;
    }

    if (isCustomRequestProduct(this.product) && isEmpty(this.state.bookingItem.comment)) {
      return true;
    }

    return false;
  }

  get isBoxShippingInformationStepDisabled() {
    return this.state.bookingItem.productId === null ||
      this.state.bookingItem.date === null ||
      !isParticipantsRangeValid(this.product, this.state.bookingItem.participants) ||
      this.props.experience.status !== 'AVAILABLE';
  }

  get isExperienceInformationStepDisabled() {
    if (this.state.bookingItem.date === null) {
      return true;
    }

    if (this.state.bookingItem.timeSlot.to === null) {
      return true;
    }

    if (this.state.bookingItem.productId === null) {
      return true;
    }

    if (!this.state.bookingItem.languageId) {
      return true;
    }

    if (this.isGeneric && this.state.bookingItem.address === null) {
      return true;
    }

    if (this.isVirtual && this.state.bookingItem.address === null) {
      return true;
    }

    if (!isParticipantsRangeValid(this.product, this.state.bookingItem.participants)) {
      return true;
    }

    if (this.props.experience.status !== 'AVAILABLE') {
      return true;
    }

    return false;
  }

  get isValidateInfoStepDisabled() {
    if (isBoxShipping(this.props.experience)) {
      return this.isBoxShippingInformationStepDisabled;
    }

    return this.isExperienceInformationStepDisabled;
  }

  get languages() {
    if (!this.props.experience.partner) {
      return this.props.languages;
    }

    return this.props.experience.languages.map(({ language }) => language);
  }

  onCityChange = ({ placeAddress }: CitySearchParams) => {
    this.setState({
      ...this.state,
      bookingItem: {
        ...this.state.bookingItem,
        address: placeAddress
      }
    });
  };

  get infoStep() {
    return <InfoStep
      type={this.props.experience.type}
      products={this.props.experience.products}
      productId={this.state.bookingItem.productId}
      participants={this.state.bookingItem.participants}
      targetCurrency={this.props.user.currency}
      date={this.state.bookingItem.date}
      languages={this.languages}
      languageId={this.state.bookingItem.languageId}
      minDuration={this.props.experience.minDuration}
      bookingDeadline={this.props.experience.bookingDeadline}
      isGeneric={isGeneric(this.props.experience)}
      isSubmitDisabled={this.isValidateInfoStepDisabled}
      onParticipantsChange={this.setParticipants}
      onDateChange={this.setDates}
      onTimeChange={this.setTime}
      onLanguageChange={this.setLanguageId}
      onProductChange={this.setProductId}
      onCityChange={this.onCityChange}
    />;
  }

  get eventStep() {
    if (this.props.bookings.status !== FETCH_STATUS.FETCHED) {
      return <Loader w="100%" h={400}/>;
    }

    if (this.state.areBudgetsLoading) {
      return <Loader w="100%" h={400}/>;
    }

    return <EventStep
      product={this.product}
      participants={this.state.bookingItem.participants}
      budgets={this.props.budgets}
      draftBookings={this.props.draftBookings}
      name={this.state.name}
      bookingId={this.state.bookingId}
      targetCurrency={this.props.user.currency}
      selectedRadio={this.state.selectedRadio}
      isCommentRequired={isCustomRequestProduct(this.product)}
      isSubmitDisabled={this.isEventStepSubmitDisabled}
      onNameChange={this.setName}
      onBookingChange={this.setBookingId}
      onBudgetChange={this.setBudgetId}
      onCommentChange={this.setComment}
      onRadioSelect={this.setSelectedRadio}
      onSubmit={this.onValidateEventStep}
    />;
  }

  render() {
    return (
      <FormTabs onInfoNext={this.onValidateInfoStep}>
        { this.infoStep }
        { this.eventStep }
      </FormTabs>
    );
  }
}

function mapStateToProps(state: RootState): StoreProps {
  return {
    bookings: state.bookings,
    budgets: getBudgetsByOrganizerIdSelector(state, state.user.id),
    draftBookings: getEditableBookingsSelector(state),
    user: state.user
  };
}

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  showToast: (payload) => dispatch(showToast(payload)),
  createBooking: (data) => dispatch(createBooking(data)).unwrap(),
  createBookingItems: (data) => dispatch(createBookingItems(data)).unwrap(),
  createPropositionsGroupThunk: (data) => dispatch(createPropositionsGroupThunk(data)).unwrap(),
  getUserBudgets: () => dispatch(getUserBudgets()),
  getBookings: (params) => dispatch(getBookings(params)),
  trackEvent: (opts) => dispatch(trackEvent(opts))
});

export default connect<StoreProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('experience')(ExperienceDetailsForm));