import { Box, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { BudgetSelect } from '@jurnee/common/src/components/BudgetSelect';
import RadioCard from '@jurnee/common/src/components/RadioCard';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useTranslation } from 'react-i18next';
import { BookingJSON } from '../../../../entities/Booking';
import { ProductJSON } from '../../../../entities/Product';
import { UserDetails } from '../../../../entities/User';
import { Step } from '../Step';
import { BookingSelect } from './BookingSelect';
import { CommentTextarea } from './CommentTextarea';

interface Props {
  participants: number;
  budgets: BudgetJSON[];
  draftBookings: BookingJSON[];
  name: string;
  bookingId: number;
  targetCurrency: UserDetails['currency'];
  selectedRadio: RadioValue;
  isCommentRequired: boolean;
  isSubmitDisabled: boolean;
  product?: ProductJSON;
  onNameChange(name: string): void;
  onBookingChange(bookingId: number): void;
  onBudgetChange(budgetId: number): void;
  onCommentChange(value: string): void;
  onRadioSelect(value: RadioValue): void;
  onSubmit(): Promise<void>;
}

export type RadioValue = 'EXISTING_EVENT' | 'NEW_EVENT';

export function EventStep(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'form' });

  return (
    <Step
      product={props.product}
      participants={props.participants}
      targetCurrency={props.targetCurrency}
      submitLabel={t('next')}
      isSubmitDisabled={props.isSubmitDisabled}
      onSubmit={props.onSubmit}
    >
      {
        props.draftBookings.length > 0 ? (
          <Box w="100%">
            <FormLabel>{t('steps.event.addTo')}</FormLabel>

            <VStack alignItems="flex-start" spacing="10px">
              <RadioCard
                isSelected={props.selectedRadio === 'EXISTING_EVENT'}
                onSelect={() => props.onRadioSelect('EXISTING_EVENT')}
                label={t('steps.event.existingEvent.label')}
              >
                <BookingSelect
                  bookings={props.draftBookings}
                  selected={props.bookingId}
                  placeholder={t('steps.event.existingEvent.placeholder')}
                  onChange={props.onBookingChange}
                />
              </RadioCard>

              <RadioCard
                isSelected={props.selectedRadio === 'NEW_EVENT'}
                onSelect={() => props.onRadioSelect('NEW_EVENT')}
                label={t('steps.event.newEvent.label')}
              >
                <FormControl mt="2px" isInvalid={isEmpty(props.name)}>
                  <Input size="sm" value={props.name} onChange={({ target }) => props.onNameChange(target.value)} />
                </FormControl>

                {
                  props.budgets.length > 0 &&
                    <BudgetSelect
                      size="sm"
                      placeholder={t('steps.event.newEvent.budget.placeholder')}
                      budgets={props.budgets}
                      onChange={props.onBudgetChange}
                    />
                }
              </RadioCard>
            </VStack>
          </Box>
        ) : (
          <VStack w="100%" alignItems="flex-start" spacing={5}>
            <FormControl isInvalid={isEmpty(props.name)} isRequired>
              <FormLabel>{t('steps.event.newEvent.name.label')}</FormLabel>
              <Input value={props.name} onChange={({ target }) => props.onNameChange(target.value)} />
            </FormControl>

            {
              props.budgets.length > 0 &&
                <BudgetSelect
                  size="md"
                  label={t('steps.event.newEvent.budget.label')}
                  placeholder={t('steps.event.newEvent.budget.placeholder')}
                  budgets={props.budgets}
                  onChange={props.onBudgetChange}
                />
            }
          </VStack>
        )
      }

      <CommentTextarea isRequired={props.isCommentRequired} onChange={props.onCommentChange} />
    </Step>
  );
}