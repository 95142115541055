import { HStack, StackProps, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';

export function ExperienceRecommendedLabel(props: StackProps) {
  const { t } = useTranslation('experience', { keyPrefix: 'specs' });

  return (
    <HStack alignItems="center" spacing={1} height={4} {...props}>
      <Icon icon="rosetteCheck" color="teal.400" size={4}/>
      <Text fontWeight={500} color="teal.400" noOfLines={1}>
        { t('recommendedByCompany') }
      </Text>
    </HStack>
  );
}