import { HStack, Spacer, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { ProductJSON } from '../../../../entities/Product';
import { UserDetails } from '../../../../entities/User';
import { PrimaryButton } from '../../../buttons/PrimaryButton';
import { PriceLine } from './PriceLine';

interface Props {
  participants: number;
  targetCurrency: UserDetails['currency'];
  submitLabel: string;
  product?: ProductJSON;
  isSubmitDisabled?: boolean;
  onSubmit(): Promise<void>;
  children: React.ReactNode;
}

export function Step(props: Props) {
  const hasPriceLine = props.product && props.participants;

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onSubmit() {
    setIsSubmitting(true);
    await props.onSubmit();
    setIsSubmitting(false);
  }

  return (
    <VStack w="100%" spacing={5} mt={3}>
      <VStack w="100%" p={0} spacing={4}>
        { props.children }
      </VStack>

      <HStack w="100%" p={0}>
        {
          hasPriceLine &&
            <PriceLine
              product={props.product}
              participants={props.participants}
              targetCurrency={props.targetCurrency}
            />
        }
        <Spacer/>

        <PrimaryButton
          size="sm"
          isDisabled={props.isSubmitDisabled || isSubmitting}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          { props.submitLabel }
        </PrimaryButton>
      </HStack>
    </VStack>
  );
}