import { Box, HStack, SimpleGridProps, UseToastOptions, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { LocationCircle, LocationRectangle, PlaceLocationBody } from '@jurnee/common/src/dtos/places';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Experience as ExperienceCard } from 'src/components/Experience/ExperienceCard/Experience';
import { Place as PlaceCard } from 'src/components/Experience/ExperienceCard/Place';
import { ExperienceCardSkeleton } from 'src/components/Experience/ExperienceCardSkeleton';
import { ExperiencesGrid } from 'src/components/Experience/ExperiencesGrid';
import { SearchBarParams } from 'src/components/SearchBar';
import { RouteProps } from 'src/pages/Route';
import router from 'src/router';
import { TrackEventOptions, trackEvent } from 'src/store/analytics/analytics.thunks';
import { getExperiences } from 'src/store/experiences/experiences.thunks';
import { resetPlaces } from 'src/store/places/places.store';
import { PlacesSearchPayload, searchNearbyThunk, textSearchThunk } from 'src/store/places/places.thunks';
import { RootState } from 'src/store/state';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { getUserFavoriteExperiences } from 'src/store/userFavoriteExperiences/userFavoriteExperiences.thunks';
import { getTypeFromPath, hasViewSelector } from 'src/utils/experiences';
import { filterDuplicatePlaces } from 'src/utils/places';
import { ExperiencesHeader } from './ExperiencesHeader';
import { ExperiencesMap } from './ExperiencesMap';
import { NoResultsCard } from './NoResultsCard';

const DEFAULT_RADIUS = 50000;

interface StateProps {
  experiences: RootState['experiences'];
  geolocation: RootState['geolocation'];
  places: RootState['places'];
  userFavoriteExperiences: RootState['userFavoriteExperiences'];
}

interface DispatchProps {
  getExperiences(params: URLSearchParams): void;
  getUserFavoriteExperiences(): void;
  textSearchThunk(data: PlacesSearchPayload): void;
  searchNearbyThunk(data: PlacesSearchPayload): void;
  trackEvent(opts: TrackEventOptions): void;
  showToast(payload: UseToastOptions): void;
  resetPlaces(): void
}

export type ExperienceView = 'grid' | 'map';

export interface ExperiencesStateFilters {
  type: ExperienceJSON['type'];
  query: string;
  location: LocationCircle & LocationRectangle & {
    description: string;
  };
}

interface State {
  filters: ExperiencesStateFilters;
  currentView: ExperienceView;
  hoverId: number | string;
  moveMap: boolean;
}

type ExperiencesRouteProps = Omit<RouteProps, 'location'> & {
  location: Omit<RouteProps['location'], 'state'> & {
    state: {
      booking?: BookingJSON;
    }
  }
}

export type Props = State & StateProps & DispatchProps & ExperiencesRouteProps & WithTranslation;

export const defaultState: State = {
  filters: {
    type: null,
    query: '',
    location: {
      description: '',
      circle: {
        lat: null,
        long: null,
        radius: DEFAULT_RADIUS
      },
      rectangle: {
        ne: {
          lat: null,
          long: null
        },
        sw: {
          lat: null,
          long: null
        }
      }
    },
  },
  currentView: 'grid',
  hoverId: null,
  moveMap: false
};

class Experiences extends React.Component<Props> {

  state: State = {
    ...defaultState,
    filters: {
      ...defaultState.filters,
      location: {
        ...defaultState.filters.location,
        description: [this.props.geolocation.data.city, this.props.geolocation.data.countryCode].join(', '),
        circle: {
          ...defaultState.filters.location.circle,
          lat: this.props.geolocation.data.latitude,
          long: this.props.geolocation.data.longitude
        }
      }
    }
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    const newState = {
      ...this.state,
      filters: {
        ...this.state.filters,
        ...this.searchParamsFilters,
        location: {
          ...this.state.filters.location,
          ...this.searchParamsFilters.location,
          circle: {
            ...this.state.filters.location.circle,
            ...this.searchParamsFilters.location.circle,
          }
        },
        type: getTypeFromPath(this.props.match),
      }
    };

    if (!hasViewSelector(newState.filters.type)) {
      Object.assign(newState, { currentView: 'grid' });
    }

    if (this.props.userFavoriteExperiences.status !== FETCH_STATUS.FETCHED) {
      this.props.getUserFavoriteExperiences();
    }

    this.fetchExperiences(newState);
    this.fetchPlaces(newState);

    this.setState(newState);
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      await this.init();
    }
  }

  get searchParamsFilters() {
    const searchParams = new URLSearchParams(location.search);

    const query = searchParams.get('query') || this.state.filters.query;
    const description = searchParams.get('location') || this.state.filters.location.description;
    const circleParams = searchParams.getAll('circle');

    const circle = {
      long: Number(circleParams[0]) || this.state.filters.location.circle.long,
      lat: Number(circleParams[1]) || this.state.filters.location.circle.lat,
    };

    return {
      query,
      location: {
        description,
        circle
      }
    };
  }

  fetchExperiences = async ({ filters }: State) => {
    const params = new URLSearchParams({ type: filters.type });

    if (!isEmpty(filters.query)) {
      params.set('query', filters.query);
    }

    const hasLocation = ['IN_OFFICE', 'IN_PERSON', 'RESTAURANT', 'BAR'].includes(filters.type);

    if (hasLocation) {
      params.set('lat', filters.location.circle.lat.toString());
      params.set('long', filters.location.circle.long.toString());
      params.set('radius', filters.location.circle.radius.toString());
    }

    this.props.getExperiences(params);
  };

  fetchPlaces = async ({ currentView, filters }: State) => {
    const { type, query, location } = filters;

    this.props.resetPlaces();

    if (!this.isFetchPlacesEnabled(filters)) {
      return;
    }

    if (isEmpty(filters.query) && !['BAR', 'RESTAURANT'].includes(type)) {
      return;
    }

    const params = new URLSearchParams();

    if (!isEmpty(query)) {
      params.set('query', query);
    }

    const placesType = ['BAR', 'RESTAURANT'].includes(type) ? type.toLowerCase() : null;

    if (placesType) {
      params.set('type', placesType);
    }

    if (currentView === 'map' && !isEmpty(query)) {
      params.append('rect', location.rectangle.sw.long.toString());
      params.append('rect', location.rectangle.sw.lat.toString());
      params.append('rect', location.rectangle.ne.long.toString());
      params.append('rect', location.rectangle.ne.lat.toString());
    } else {
      params.append('circle', location.circle.long.toString());
      params.append('circle', location.circle.lat.toString());
      params.append('circle', location.circle.radius.toString());
    }

    if (isEmpty(query)) {
      this.props.searchNearbyThunk({ params, onError: () => {
        const title = this.props.t('placeSearch.error');
        this.props.showToast({ title, status: 'error' });
      }});
    } else {
      this.props.textSearchThunk({ params, onError: () => {
        const title = this.props.t('placeSearch.error');
        this.props.showToast({ title, status: 'error' });
      }});
    }
  };

  get isResultsLoading() {
    if (this.props.experiences.status !== FETCH_STATUS.FETCHED) {
      return true;
    }

    if (this.isFetchPlacesEnabled(this.state.filters) && this.props.places.status !== FETCH_STATUS.FETCHED) {
      return true;
    }

    return false;
  }

  isFetchPlacesEnabled = (filters: State['filters']) => {
    if (filters.type === 'IN_PERSON' && isEmpty(filters.query)) {
      return false;
    }

    return ['IN_PERSON', 'RESTAURANT', 'BAR'].includes(filters.type);
  };

  skeletonGrid(props: SimpleGridProps) {
    return (
      <ExperiencesGrid {...props}>
        { Array(18).fill(0).map((_x, idx) => <ExperienceCardSkeleton key={idx} />) }
      </ExperiencesGrid>
    );
  }

  onCardEnter = (hoverId: number | string) => {
    this.setState({ ...this.state, hoverId });
  };

  onCardLeave = () => {
    this.setState({ ...this.state, hoverId: null });
  };

  get experiences() {
    if (this.state.currentView === 'map') {
      return this.props.experiences.list.filter(e => e.partner?.address?.lat && e.partner?.address?.long);
    }

    return this.props.experiences.list;
  }

  get places() {
    if (isEmpty(this.state.filters.query) && !['RESTAURANT', 'BAR'].includes(this.state.filters.type)) {
      return [];
    }

    return filterDuplicatePlaces(this.props.places.list, this.experiences);
  }

  renderMap() {
    return (
      <HStack w="100%" h="calc(100vh - 120px)" spacing={0} display="flex" alignItems="stretch">
        <Box
          w="50%"
          px={8}
          py={5}
          borderRight="1px solid"
          borderColor="gray.200"
          maxHeight="calc(100vh - 120px)"
          overflowY="auto"
        >
          { this.renderGrid({ columns: { sm: 1, md: 1, lg: 2, xl: 2, '2xl': 3, '3xl': 4 } }) }
        </Box>

        <Box w="50%">
          <ExperiencesMap
            lat={this.state.filters.location.circle.lat}
            long={this.state.filters.location.circle.long}
            experiences={this.props.experiences.list}
            places={this.props.places.list}
            hoverId={this.state.hoverId}
            moveMap={this.state.moveMap}
            isLoading={this.isResultsLoading}
            onMoveEnd={this.updateLocation}
            onBoundsChange={this.triggerSearchFromMapBounds}
            onSearch={this.triggerSearchFromState}
          />
        </Box>
      </HStack>
    );
  }

  renderGrid(props: SimpleGridProps = {}) {
    if (this.isResultsLoading) {
      return this.skeletonGrid(props);
    }

    if ([...this.experiences, ...this.places].length === 0) {
      return <NoResultsCard type={this.state.filters.type} bookingOrigin={this.bookingOrigin} />;
    }

    return (
      <VStack w="100%" spacing={8}>
        <ExperiencesGrid {...props}>
          {
            this.experiences.map(experience =>
              <ExperienceCard
                key={experience.id}
                experience={experience}
                onMouseEnter={() => this.onCardEnter(experience.id)}
                onMouseLeave={() => this.onCardLeave()}
              />
            )
          }
          {
            this.places.map(place =>
              <PlaceCard
                key={place.id}
                place={place}
                onMouseEnter={() => this.onCardEnter(place.id)}
                onMouseLeave={() => this.onCardLeave()}
              />
            )
          }
        </ExperiencesGrid>
      </VStack>
    );
  }

  handleSearchParams = (filters: State['filters']) => {
    const params = new URLSearchParams();

    if (!isEmpty(filters.query)) {
      params.set('query', filters.query);
    }

    const hasLocation = ['IN_OFFICE', 'IN_PERSON', 'RESTAURANT', 'BAR'].includes(filters.type);

    if (hasLocation) {
      params.set('location', filters.location.description);
      params.append('circle', filters.location.circle.long.toString());
      params.append('circle', filters.location.circle.lat.toString());
      params.append('circle', filters.location.circle.radius.toString());
    }

    router.navigate(`${this.props.location.pathname}?${params.toString()}`, { state: { booking: this.bookingOrigin } });
  };

  onSearchSubmitted = ({ query, cityParams }: SearchBarParams) => {
    const partial = cityParams ? {
      circle: {
        ...this.state.filters.location.circle,
        lat: cityParams.placeAddress.latitude,
        long: cityParams.placeAddress.longitude
      },
      description: cityParams.description
    } : {};

    const newState = {
      ...this.state,
      filters: {
        ...this.state.filters,
        query,
        location: {
          ...this.state.filters.location,
          ...partial
        }
      }
    };

    if (this.state.currentView === 'map') {
      Object.assign(newState, { moveMap: true });
    } else {
      this.handleSearchParams(newState.filters);
      this.fetchExperiences(newState);
      this.fetchPlaces(newState);
    }

    this.setState(newState);
  };

  updateLocation = (location: PlaceLocationBody) => {
    const filters = {
      ...this.state.filters,
      location: {
        ...this.state.filters.location,
        ...location
      }
    };

    this.setState({ ...this.state, filters });
  };

  triggerSearchFromState = () => {
    this.handleSearchParams(this.state.filters);
    this.fetchPlaces(this.state);
    this.fetchExperiences(this.state);
  };

  triggerSearchFromMapBounds = (location: PlaceLocationBody) => {
    const newState = {
      ...this.state,
      filters: {
        ...this.state.filters,
        location: {
          ...this.state.filters.location,
          ...location
        }
      },
      moveMap: false
    };

    this.handleSearchParams(newState.filters);
    this.fetchExperiences(newState);
    this.fetchPlaces(newState);

    this.setState(newState);
  };

  get bookingOrigin() {
    if (this.props.location.state && this.props.location.state.booking) {
      return this.props.location.state.booking;
    }

    return null;
  }

  onViewChange = (currentView: ExperienceView) => {
    const newState = { ...this.state, currentView };

    this.fetchExperiences(newState);
    this.fetchPlaces(newState);

    this.setState(newState);
  };

  render() {
    return (
      <main>
        <ExperiencesHeader
          filters={this.state.filters}
          booking={this.bookingOrigin}
          defaultCityValue={this.state.filters.location.description}
          hasCitySearch={this.state.filters.type !== 'VIRTUAL'}
          onSearchSubmitted={this.onSearchSubmitted}
          currentView={this.state.currentView}
          onViewChange={this.onViewChange}
        />

        {
          this.state.currentView === 'map' ? (
            this.renderMap()
          ) : (
            <Box px={8} py={5}>
              { this.renderGrid() }
            </Box>
          )
        }
      </main>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    experiences: state.experiences,
    geolocation: state.geolocation,
    places: state.places,
    userFavoriteExperiences: state.userFavoriteExperiences
  };
}

const mapDispatchToProps: DispatchProps = {
  getExperiences,
  getUserFavoriteExperiences,
  textSearchThunk,
  searchNearbyThunk,
  trackEvent,
  showToast,
  resetPlaces
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('experiences')(Experiences));