import { FormControl, FormLabel, VStack, useToast } from '@chakra-ui/react';
import { FileInput } from '@jurnee/common/src/components/FileInput';
import { SkeletonFileInput } from '@jurnee/common/src/components/Skeleton/SkeletonFileInput';
import { ATTACHMENT_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { TaskAttachmentJSON } from '@jurnee/common/src/entities/TaskAttachment';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useTranslation } from 'react-i18next';
import { getTaskAttachmentUrl } from 'src/api/tasks';

interface Props {
  task: TaskJSON | null;
  attachments: TaskAttachmentJSON[];
  files: File[];
  isLoading: boolean;
  isUploading: boolean;
  isDeleteDisabled: boolean;
  onRemoveAttachment(id: TaskAttachmentJSON['id']): void;
  onAddFile(file: File): void;
  onRemoveFile(identifier: number): void;
}

export function Attachments(props: Props) {
  const toast = useToast();
  const { t } = useTranslation(['tasks', 'common']);

  async function onDownloadAttachment(taskAttachmentId: TaskAttachmentJSON['id']) {
    try {
      const { url } = await getTaskAttachmentUrl({
        bookingId: props.task.bookingId,
        taskId: props.task.id,
        taskAttachmentId
      });

      window.open(url, '_blank');
    } catch(error) {
      toast(getErrorToast(t('drawer.toasts.downloadFile.error'), error.message));
    }
  }

  return (
    <FormControl id="attachments">
      <FormLabel>{t('drawer.form.attachments.label')}</FormLabel>

      {
        props.isLoading ? (
          <VStack spacing={2}>
            <SkeletonFileInput />
            <SkeletonFileInput />
            <SkeletonFileInput />
          </VStack>
        ) : (
          <VStack spacing={2}>
            {
              props.attachments.map(({ id, filename, size }) =>
                <FileInput
                  key={id}
                  label={t('common:fields.fileDocument.label')}
                  sublabel={t('common:fields.fileDocument.attachmentTypes')}
                  contentTypes={ATTACHMENT_CONTENT_TYPES}
                  labelMaxWidth={150}
                  defaultName={filename}
                  defaultSize={size}
                  defaultIdentifier={id}
                  isSavedFile={true}
                  onDownload={onDownloadAttachment}
                  onRemove={!props.isDeleteDisabled && props.onRemoveAttachment}
                />
              )
            }

            {
              props.files.map(({ name, size, lastModified }) =>
                <FileInput
                  key={lastModified}
                  label={t('common:fields.fileDocument.label')}
                  sublabel={t('common:fields.fileDocument.attachmentTypes')}
                  contentTypes={ATTACHMENT_CONTENT_TYPES}
                  labelMaxWidth={150}
                  defaultName={name}
                  defaultSize={size}
                  defaultIdentifier={lastModified}
                  isLoading={props.isUploading}
                  onRemove={props.onRemoveFile}
                />
              )
            }

            <FileInput
              key={new Date().getTime()}
              label={t('common:fields.fileDocument.label')}
              sublabel={t('common:fields.fileDocument.attachmentTypes')}
              contentTypes={ATTACHMENT_CONTENT_TYPES}
              onChange={props.onAddFile}
            />
          </VStack>
        )
      }
    </FormControl>
  );
}