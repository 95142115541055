import { Box } from '@chakra-ui/react';
import { sanitize } from '@jurnee/common/src/utils/strings';

interface Props {
  value: string;
}

export function Comment({ value }: Props) {
  return (
    <Box
      key={0}
      w="100%"
      fontSize={14}
      color="gray.400"
      sx={{ 'a': { textDecoration: 'underline' } }}
      dangerouslySetInnerHTML={{ __html: sanitize(value, ['div']) }}
    />
  );
}