import { api } from '@jurnee/common/src/browser/Api';
import { PlaceAddressDTO, PlaceDescriptionContextBody, PlaceDTO, PlacesAutocompleteBody } from '@jurnee/common/src/dtos/places';
import { List } from '@jurnee/common/src/serializers';
import { PlaceJSON } from '../entities/Place';

export function getPlacesAutoComplete(payload: PlacesAutocompleteBody): Promise<List<PlaceDTO, never>> {
  return api.post(`places/autocomplete`, payload);
}

export function getPlaceAddress(placeId: string): Promise<PlaceAddressDTO> {
  return api.get(`places/${placeId}/address`);
}

export function getPlaceDescription(placeId: string, payload: PlaceDescriptionContextBody): Promise<{ content: string }> {
  return api.post(`places/${placeId}/description`, payload);
}

export function getPlace(placeId: string): Promise<PlaceJSON> {
  return api.get(`places/${placeId}`);
}