import { HStack, Heading, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ImageJSON } from '../../../entities/Image';
import { ExperienceRecommendedLabel } from '../../experiences/ExperienceRecommendedLabel';
import { ImagesGrid } from './ImagesGrid';

interface Props {
  title: string;
  images: ImageJSON[];
  isCompanyRecommended?: boolean;
  children?: ReactNode;
}

export function ExperienceHeader(props: Props) {
  return (
    <VStack w="100%" spacing={8}>
      <VStack w="100%" spacing={2}>
        <HStack w="100%" justifyContent="space-between" h={7}>
          <Heading size="xl" noOfLines={1}>{props.title}</Heading>

          <HStack spacing={5}>
            { props.children }
          </HStack>
        </HStack>

        { props.isCompanyRecommended && <ExperienceRecommendedLabel alignSelf="flex-start" /> }
      </VStack>

      <ImagesGrid images={props.images} />
    </VStack>
  );
}