import { Box, FormControl, FormLabel, GridItem, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LanguageJSON } from '../../../../entities/Language';
import AddressSearch, { CitySearchParams } from '../../../AddressSearch';
import { FromDatePicker } from '../../../FromDatePicker';
import { LanguageSelect } from '../../../LanguageSelect';
import { ParticipantsInput } from '../../../ParticipantsInput';
import { TimeSlot } from '../../../TimeSlotSelector';
import { TimeSlotSelect } from './TimeSlotSelect';

interface Props {
  participants: number;
  maxParticipants: number;
  areParticipantsValid: boolean;
  date: Date;
  languages: LanguageJSON[];
  languageId: number;
  isVirtual: boolean;
  bookingDeadline?: number;
  minDuration?: number;
  isGeneric?: boolean;
  onParticipantsChange(participants: number): void;
  onDateChange(date: Date): void;
  onTimeChange(slot: TimeSlot): void;
  onLanguageChange(languageId: number): void;
  onCityChange(data: CitySearchParams): void;
}

export function ExperienceFields(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'form.steps.information' });

  return (
    <SimpleGrid w="100%" columns={2} spacingY={4} spacingX={5}>
      <FromDatePicker
        label={t('date.label')}
        bookingDeadline={props.bookingDeadline}
        selected={props.date}
        onChange={props.onDateChange}
        inputProps={{ size: 'sm' }}
        isRequired
      />

      <TimeSlotSelect
        date={props.date}
        minDuration={props.minDuration}
        isRequired={true}
        onChange={props.onTimeChange}
      />

      <ParticipantsInput
        size="sm"
        label={t('participants.label')}
        defaultParticipants={props.participants}
        maxParticipants={props.maxParticipants}
        onChange={props.onParticipantsChange}
        isParticipantsRangeValid={props.areParticipantsValid}
        isRequired
      />

      <LanguageSelect
        size="sm"
        label={t('language.label')}
        languages={props.languages}
        languageId={props.languageId}
        placeholder={t('language.placeholder')}
        isDisabled={props.languages.length === 1}
        onChange={e => props.onLanguageChange(Number(e.target.value))}
        isRequired
      />

      {
        (props.isGeneric || props.isVirtual) && (
          <GridItem colSpan={2}>
            <FormControl isRequired>
              <FormLabel>{t(`${props.isVirtual ? 'timezone' : 'city'}.label`)}</FormLabel>
              <Box className="react-select-small">
                <AddressSearch onChange={props.onCityChange} types='(cities)' />
              </Box>
            </FormControl>
          </GridItem>
        )
      }
    </SimpleGrid>
  );
}