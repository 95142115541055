import { HStack, Spacer, Text } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getUserBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { getUserLabel, sortByUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PriceInUserCurrency } from 'src/components/PriceInUserCurrency';
import { RemovableItemsList } from 'src/components/RemovableItemsList';
import EmptyStateList from 'src/components/RemovableItemsList/EmptyStateList';
import { RemovableItem } from 'src/components/RemovableItemsList/RemovableItem';
import { getUserBudgetBreakdownById } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.selectors';

interface Props {
  budget: BudgetJSON;
  users: UserDetails[];
  userIds: number[];
  onRemoveUser(user: UserDetails): void;
}

interface RemovableUserProps {
  budget: BudgetJSON;
  user: UserDetails;
  onRemoveUser(user: UserDetails): void;
}

function getRemaining({ budget, user }: Omit<RemovableUserProps, 'onRemoveUser'>) {
  const userBudgetBreakdown = useSelector(getUserBudgetBreakdownById(user.id));

  if (!userBudgetBreakdown) {
    return null;
  }

  if (user.budgetId !== budget?.id) {
    return null;
  }

  return getUserBudgetRemaining(userBudgetBreakdown);
}

function RemovableUser({ onRemoveUser, ...props }: RemovableUserProps) {
  const remaining = getRemaining(props);

  return (
    <RemovableItem key={props.user.id} onRemove={() => onRemoveUser(props.user)}>
      <HStack w="100%" spacing="10px">
        <Avatar user={props.user} borderRadius={4} />
        <Text>{getUserLabel(props.user)}</Text>
        {
          typeof remaining === 'number' && (
            <>
              <Spacer />
              <Text color={remaining > 0 ? 'gray.400' : 'red.500' }>
                <PriceInUserCurrency value={remaining} currency='EUR' />
              </Text>
            </>
          )
        }
      </HStack>
    </RemovableItem>
  );
}

export function UsersList({ budget, users, userIds, onRemoveUser }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: `budgets.drawer.form.users.INDIVIDUAL` });

  if (userIds.length === 0) {
    return <EmptyStateList description={t('noUsers')} />;
  }

  const removableUsers = userIds.map(userId => users.find(user => userId === user.id));

  return (
    <RemovableItemsList>
      {sortByUserLabel(removableUsers).map(user =>
        <RemovableUser
          key={user.id}
          budget={budget}
          user={user}
          onRemoveUser={onRemoveUser}
        />
      )}
    </RemovableItemsList>
  );
}