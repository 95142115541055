import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Children, cloneElement, ComponentType, isValidElement, ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStep } from './EventStep';
import { InfoStep } from './InfoStep';

interface Props {
  onInfoNext?(): void;
  children?: ReactNode;
}

export function FormTabs(props: Props) {
  const { t } = useTranslation('experience', { keyPrefix: 'form' });

  const [step, setStep] = useState(0);

  function onChangeTab(index: number) {
    if (index >= step) {
      return;
    }

    setStep(index);
  }

  async function onInfoNext() {
    setStep(1);
    props.onInfoNext && props.onInfoNext();
  }

  function getStep<T>(type: ComponentType<T>): ReactElement<T> | undefined {
    return Children.toArray(props.children).find(
      (child): child is ReactElement<T> => isValidElement(child) && child.type === type
    );
  }

  return (
    <Tabs
      bg="white"
      border="1px solid"
      borderColor="blue.50"
      borderRadius={8}
      defaultIndex={step}
      index={step}
      minW={450}
      p="8px 20px 20px 20px"
      position="sticky"
      top={10}
      w="100%"
      onChange={onChangeTab}
    >
      <TabList px={0} gap={0}>
        <Tab noOfLines={1} w="50%" h="32px" display="inline-flex" alignItems="center" p={0}>
          { t('steps.information.tabLabel') }
        </Tab>

        <Tab noOfLines={1} w="50%" h="32px" display="inline-flex" alignItems="center" p={0}>
          { t('steps.event.tabLabel') }
        </Tab>
      </TabList>

      <TabPanels p={0}>
        <TabPanel>
          { cloneElement(getStep(InfoStep), { onInfoNext }) }
        </TabPanel>

        <TabPanel>
          { getStep(EventStep) }
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}