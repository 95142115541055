import { IconKey } from '@jurnee/common/src/components/Icon';
import { CatalogType, CompanyJSON } from '@jurnee/common/src/entities/Company';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { PathMatch } from 'react-router-dom';

export type CustomCategoryType = 'SPECIFIC_PARTNER' | 'CUSTOM_REQUEST' | 'PAY_VIA_JURNEE';

export const CUSTOM_CATEGORY_TYPES: CustomCategoryType[] = ['SPECIFIC_PARTNER', 'CUSTOM_REQUEST', 'PAY_VIA_JURNEE'];

export type ExperienceCategory = {
  icon: IconKey;
  type: ExperienceJSON['type'] | CustomCategoryType | 'MEETING_ROOM' | 'TRANSPORT';
  path: string;
}

export const EXPERIENCES_CATEGORIES: ExperienceCategory[] = [
  {
    icon: 'teams',
    type: 'IN_PERSON',
    path: '/experiences/in-person'
  },
  {
    icon: 'restaurant',
    type: 'RESTAURANT',
    path: '/experiences/restaurant'
  },
  {
    icon: 'glass',
    type: 'BAR',
    path: '/experiences/bar'
  },
  {
    icon: 'confetti',
    type: 'IN_OFFICE',
    path: '/experiences/in-office'
  },
  {
    icon: 'camera',
    type: 'VIRTUAL',
    path: '/experiences/virtual'
  },
  {
    icon: 'slideShow',
    type: 'MEETING_ROOM',
    path: null
  },
  {
    icon: 'package',
    type: 'BOX_SHIPPING',
    path: '/experiences/box-shipping'
  },
  {
    icon: 'taxi',
    type: 'TRANSPORT',
    path: null
  },
  {
    icon: 'bell',
    type: 'CUSTOM_REQUEST',
    path: null
  },
  {
    icon: 'pinUser',
    type: 'SPECIFIC_PARTNER',
    path: null
  },
  {
    icon: 'receipt',
    type: 'PAY_VIA_JURNEE',
    path: null
  },
];

export function getExperienceCategories({ excludedCatalogs, hasPath }: { excludedCatalogs: CompanyJSON['excludedCatalogs'], hasPath?: boolean }) {
  return EXPERIENCES_CATEGORIES.filter(({ type, path }) => {
    if (hasPath && !path) {
      return false;
    }

    if (CUSTOM_CATEGORY_TYPES.includes(type as CustomCategoryType)) {
      return false;
    }

    return !excludedCatalogs.includes(type as CatalogType);
  });
}

export function getTypeFromPath(match: PathMatch<string>) {
  return match.params.type.toUpperCase().replace('-', '_') as ExperienceJSON['type'];
}

export function hasViewSelector(type: ExperienceJSON['type']) {
  return ['IN_PERSON', 'BAR', 'RESTAURANT'].includes(type);
}