import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { getExperiencePath, isCompanyRecommended, isGeneric as isGenericExperience } from '@jurnee/common/src/utils/experiences';
import { isCustomRequestProduct } from '@jurnee/common/src/utils/products';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { companyRecommendedPartnersSelector } from 'src/store/companyRecommendedPartners/companyRecommandedPartners.selectors';
import { getExperienceRatingById } from 'src/store/experiencesRatings/experiencesRatings.selectors';
import { ExperienceCard } from '.';
import { Buttons } from './Buttons';

interface Props {
  experience: ExperienceJSON;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

export function Experience(props: Props) {
  const companyRecommendedPartners = useSelector(companyRecommendedPartnersSelector);
  const rating = useSelector(getExperienceRatingById(props.experience.id));

  const products = useMemo(() => props.experience.products.filter(product => !isCustomRequestProduct(product)), [props.experience.products]);
  const isRecommended = useMemo(() => isCompanyRecommended(props.experience, companyRecommendedPartners), [props.experience, companyRecommendedPartners]);
  const isGeneric = useMemo(() => isGenericExperience(props.experience), [props.experience]);
  const imageUrl = useMemo(() => props.experience.experiencesImages.length > 0 ? getCdnImageUrl(props.experience.experiencesImages[0].image.path) : '', [props.experience]);
  const to = useMemo(() => getExperiencePath(props.experience), [props.experience]);
  const address = props.experience.partner?.address ? `${props.experience.partner?.address.city} ${props.experience.partner?.address.postalCode}` : null;

  return (
    <ExperienceCard
      src={imageUrl}
      to={to}
      title={props.experience.name}
      rating={rating}
      address={address}
      products={products}
      isGeneric={isGeneric}
      isRecommended={isRecommended}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Buttons experience={props.experience} />
    </ExperienceCard>
  );
}