import { SkeletonText as ChakraSkeletonText, SkeletonTextProps } from '@chakra-ui/react';

export function SkeletonText(props: SkeletonTextProps) {
  return <ChakraSkeletonText
    noOfLines={1}
    speed={0.8}
    startColor="gray.100"
    endColor="gray.200"
    { ...props }
  />;
}